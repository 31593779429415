import { AccessLevel } from "../enums/access-level";
import { AuthenticationService } from "../enums/auth-method";
export class User {
    constructor() {}

    public id?: number;
    public email: string;
    public name: string;
    public password?: string;
    public organizationName?: string;
    public organizationId?: number;
    public ownerOrganizationName?: string;
    public ownerOrganizationId?: number;
    public accessLevel: AccessLevel;
    public authenticationService: AuthenticationService;
    public isMfaRequired: boolean;
}
