import { container, singleton } from "tsyringe";
import { RunAuditLogModel } from "../models/audit-log";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class AuditLogApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getRunLogAsync(orgId: number, startDate: string, endDate?: string, pageIndex = 1, recordsPerPage = 100) {
        return this.getObjectAsync<{ auditLog: RunAuditLogModel[]; totalRecordCount: number }>(
            `/api/audit-log/${orgId}/run-log/?startDate=${startDate}&endDate=${endDate}&pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}`,
            this._authService.token
        );
    }

    async getRunLogDetailsAsync(orgId: number, logId: number) {
        return this.getObjectAsync<any>(`/api/audit-log/${orgId}/run-log/${logId}/details`, this._authService.token);
    }

    async getConfigLogAsync(orgId: number, startDate: string, endDate?: string, pageIndex = 1, recordsPerPage = 100) {
        return this.getObjectAsync<{ auditLog: RunAuditLogModel[]; totalRecordCount: number }>(
            `/api/audit-log/${orgId}/config-log/?startDate=${startDate}&endDate=${endDate}&pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}`,
            this._authService.token
        );
    }

    async getConfigLogDetailsAsync(orgId: number, logId: number) {
        return this.getObjectAsync<{ details: string; configJson: string }>(
            `/api/audit-log/${orgId}/config-log/${logId}/details`,
            this._authService.token
        );
    }
    async deleteOrganizationAsync(orgId: number) {
        return this.deleteObjectAsync(`/api/audit-log/${orgId}`, this._authService.token);
    }
    async deleteSystemAsync() {
        return this.deleteObjectAsync(`/api/audit-log/system`, this._authService.token);
    }
}
