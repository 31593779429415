import { ProxiesViewModel } from "./proxies-view-model";

export class ProxyViewModel {
        
    constructor() {        
    }
    
    name?: string;
    description?: string;
    proxies?: ProxiesViewModel[]
}