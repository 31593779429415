import { container, inject, singleton } from 'tsyringe';
import { ProxyPoolsViewModel } from '../models/proxy-pools-view-model';
import { ProxyViewModel } from '../models/proxy-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';


@singleton()
export class ProxyApi extends BaseApi {
    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }
    async connectAsync(id: number) {
        return this.postObjectAsync(`/api/server/${id}/connect`, undefined, this._authService.token);
    }
    async getAllProxyPoolsAsync(pageIndex = 1, recordsPerPage = 100, proxyPoolName: string = '', sortOrder: number = -1) {
        return this.getObjectAsync<ProxyPoolsViewModel>(`/api/proxy?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&proxyPoolName=${proxyPoolName}&sortOrder=${sortOrder}`, this._authService.token);
    }
    async createAsync(proxy: ProxyViewModel) {
        return this.postObjectReturnObjectAsync<{ id: number }>(`/api/proxy`, proxy, this._authService.token);
    }
    async getProxyPoolAsync(id: number) {
        return this.getObjectAsync<ProxyViewModel>(`/api/proxy/${id}`, this._authService.token);
    }
    async updateAsync(proxyPoolId: number, proxy: ProxyViewModel) {
        return this.putObjectAsync(`/api/proxy/${proxyPoolId}`, proxy, this._authService.token);
    }
    async deleteAllAsync(proxyIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/proxy/all`, { ids: proxyIds }, this._authService.token);
    }
    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/proxy/${id}`, this._authService.token);
    }
}