import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ProxyUtil } from "../../../utils/proxy-util";
import { Organization } from "../../models/organization";
import { ProxiesViewModel } from "../../models/proxies-view-model";
import { ProxyViewModel } from "../../models/proxy-view-model";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { ProxyService } from "../../services/proxy.service";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";
import { SeLoadingPanel } from "../components/loading-panel.element";

@customElement("se-proxy-editor")
export class SeProxyEditorElement extends LitElement {
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _proxyService: ProxyService;
    private _orgService: OrganizationService;
    private _isNew = false;
    private _proxyPoolId?: number;
    @state() private _proxy: ProxyViewModel;
    private _proxies: ProxiesViewModel[];
    private _proxiesList: string;
    private _orgs: Organization[];
    @state() private _isLoading = false;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#description") private _descriptionEditor: TextAreaEditorElement;
    @query("#proxies") private _proxiesEditor: TextAreaEditorElement;
    @query("#loadingPanel") private _loadingPanelElement: SeLoadingPanel;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._proxyService = container.resolve(ProxyService);
        this._orgService = container.resolve(OrganizationService);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.proxyPoolId) {
            this._proxyPoolId = parseInt(location.params.proxyPoolId.valueOf() as string);
            this._isNew = false;
            this.loadProxies();
        } else {
            this._isNew = true;
            this._proxy = new ProxyViewModel();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async loadProxies() {
        try {
            this._isLoading = true;
            const res = await this._proxyService.api.getProxyPoolAsync(this._proxyPoolId);
            if (res.isOk) {
                this._proxy = res.value;
                this._proxiesList = this._proxy.proxies.map((e) => ProxyUtil.toProxyString(e)).join("\n");
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        } finally {
            this._isLoading = false;
        }
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._proxies = this._proxiesEditor.liveValue.split("\n")?.map((e) => ProxyUtil.ParseProxy(e)) || [];
            const proxy = { name: this._nameEditor.liveValue, description: this._descriptionEditor.liveValue, proxies: this._proxies };
            const res = this._isNew
                ? await this._proxyService.api.createAsync(proxy)
                : await this._proxyService.api.updateAsync(this._proxyPoolId, proxy);
            if (res.isOk) {
                history.back();
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Add New" : "Edit"} Proxy</div>
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{
                        borderTop: "solid 1px lightgray",
                        backgroundColor: "white",
                        minHeight: "50px",
                        width: "100%",
                        borderRadius: "5px 5px",
                        border: "1px solid gray",
                        boxShadow: "2px 2px 2px lightGray",
                    }}
                    .isLoading=${this._isLoading}
                >
                    <div class="scroll-container">
                        <se-input-editor
                            id="name"
                            name="name"
                            type="text"
                            label="Name"
                            labelPosition="top"
                            input-type="text"
                            required
                            size="30"
                            .value=${this._proxy?.name}
                        ></se-input-editor>
                        <se-textarea-editor
                            id="description"
                            name="description"
                            type="text"
                            label="Description"
                            labelPosition="top"
                            input-type="text"
                            size="30"
                            .value=${this._proxy?.description ?? ""}
                        ></se-textarea-editor>
                        <se-textarea-editor
                            id="proxies"
                            name="proxies"
                            type="text"
                            label="Proxies"
                            labelPosition="top"
                            input-type="text"
                            required
                            rows="5"
                            size="30"
                            .value=${this._proxiesList ?? ""}
                        ></se-textarea-editor>
                    </div>
                    <div class="savePanel">
                        <se-primary-button
                            .action="${() => this.saveAsync()}"
                            action-delay="500"
                            text="${this._isNew ? "Add" : "Save"} Proxy"
                        ></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                    </div>
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
            min-width: 400px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
