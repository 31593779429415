import { container, inject,singleton } from 'tsyringe';
import { ConfigScope } from '../enums/config-scope';
import { Space } from '../models/space';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';

@singleton()
export class SpaceApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<Space>(`/api/space/${id}`, this._authService.token);
    }

    async createAsync(name: string, isDefaultAccess: boolean, scope: ConfigScope) {
        return this.postObjectAsync(`/api/space`, { name: name, isDefaultAccess: isDefaultAccess, scope: scope }, this._authService.token);
    }

    async updateAsync(id: number, name: string, isDefaultAccess: boolean, scope: ConfigScope) {
        return this.putObjectAsync(`/api/space/${id}`, { name: name, isDefaultAccess: isDefaultAccess, scope: scope }, this._authService.token);
    }

    async deleteAllAsync(spaceIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/space/all`, { ids: spaceIds }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/space/${id}`, this._authService.token);
    }
}