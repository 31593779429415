import { container, singleton } from "tsyringe";
import { RunFileViewModel } from "../models/run-file-model";
import { RunDetailsViewModel, RunViewModel } from "../models/run-view-model";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class RunHistoryApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getManyAsync(configId: number, pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<{ configName: string; runs: RunViewModel[]; totalRecordCount: number }>(
            `/api/run-history/config/${configId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }

    async getAsync(runId: number) {
        return this.getObjectAsync<{ configName: string; run: RunDetailsViewModel; files: RunFileViewModel[] }>(
            `/api/run-history/${runId}`,
            this._authService.token
        );
    }
    async rerunMany(configId: number, runIds: number[]) {
        return this.postObjectAsync(`/api/run-history/config/${configId}/rerun/many`, { ids: runIds }, this._authService.token);
    }
    async rerun(configId: number, runId: number) {
        return this.postObjectAsync(`/api/run-history/config/${configId}/run/${runId}`, null, this._authService.token);
    }
    async deleteManyAsync(configId: number, runIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/run-history/config/${configId}/many`, { ids: runIds }, this._authService.token);
    }
    async deleteAsync(configId: number, runId: number) {
        return this.deleteObjectAsync(`/api/run-history/${runId}/config/${configId}`, this._authService.token);
    }
    async onOpenFile(runId: number, fileId: number, fileReturnType: string) {
        const response = await this.postObjectReturnObjectAsync<{ downloadUrl: string }>(
            `/api/run-history/${runId}/file/${fileId}/download/authorize`,
            null,
            this._authService.token
        );

        if (response.isOk) {
            const url = response.value.downloadUrl;
            if (url != null) {
                window.open(url + `&fileReturnType=${fileReturnType}`, "_blank");
            }
        }
    }

}
