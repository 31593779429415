import { PreventAndRedirectCommands, RedirectResult, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { BillingService } from "../../services/billing.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { SortOrder } from "se-shared/enums/sort-order";
import { TransactionType } from "../../enums/transaction-type";
import { choose } from "lit/directives/choose.js";
import { format } from "date-fns";

@customElement("se-transactions")
export class SeTransactionsElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _billingService: BillingService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];

    private _pageIndex = 1;
    private _recordsPerPage = 20;
    private _totalRecordCount: number;
    private _name = "";
    private _sortColumn = "created";
    private _sortOrder = -1;

    @state() private _data: any[] = [];

    @query("se-data-grid") private _dataGrid: SeDataGrid;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._billingService = container.resolve(BillingService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    firstUpdated() {}

    private async loadData() {
        this._columns = [
            { name: "" },
            {
                field: "created",
                title: "Date",
                sortable: true,
                align: "left",
                template: (row) => html`${format(new Date(row.created), "MMM d, yyyy, h:mm a")}`,
            },
            {
                field: "transactionType",
                sortable: true,
                title: "Transaction Type",
                align: "center",
                template: (row) =>
                    html`${choose(
                        row.transactionType,
                        [
                            [undefined || null, () => this.getTransaction("", "Unknown")],
                            [TransactionType.Initial, () => this.getTransaction("blue", "Initial")],
                            [TransactionType.Credit, () => this.getTransaction("green", "Credit")],
                            [TransactionType.Debit, () => this.getTransaction("red", "Debit")],
                            [TransactionType.Refund, () => this.getTransaction("green", "Refund")],
                        ],
                        () => html`${TransactionType[row.transactionType]}`
                    )}`,
            },
            {
                field: "amount",
                title: "Amount",
                align: "center",
                template: (row) => html`${"$" + (row.transactionType === 2 ? "-" : "") + row.amount.toFixed(2)}`,
            },
            { field: "balance", title: "Balance", align: "center", template: (row) => html`${"$" + row.balance.toFixed(2)}` },
        ];
        const destSearchReq = {
            name: this._name,
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            sortColumn: this._sortColumn,
            sortOrder: this._sortOrder,
        };

        const result = await this._billingService.api.getTransactionsAsync(destSearchReq);
        if (result.isOk) {
            this._data = result.value.creditHistory;
            this._totalRecordCount = result.value.totalRecordCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }
    private getTransaction(color: string, type: string) {
        return html`<div style="color: ${color}">${type}</div>`;
    }
    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        this._sortColumn = evt.detail.sortColumn;
        this._sortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : 0;
        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    render() {
        return html`
            <se-data-grid
                class="grid"
                .rows=${this._data}
                .columns=${this._columns}
                @sortdata=${this.sortDataGrid}
                placeholder="No transactions available."
            ></se-data-grid>
            <div style="overflow:hidden;display:flex;justify-content: center;">
                <se-pagination
                    .recordsPerPage=${this._recordsPerPage}
                    .recordCount=${this._totalRecordCount}
                    @pagechanged=${this.onPageChanged}
                ></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header {
            background-color: whitesmoke;
            border: 1px solid gray;
            padding: 1px;
            border-radius: 4px;
            padding-top: 2px;
            margin-right: auto;
            margin-left: 0px;
            color: white;
            cursor: default;
            user-select: none;
        }
        .active-tab {
            padding: 2px 10px;
            border-radius: 4px;
            color: white !important;
            background-color: var(--color-secondary);
        }
        .tabs {
            padding: 2px 5px;
            user-select: none;
            color: black;
            display: flex;
            gap: 4px;
            align-items: center;
        }
        .top-tabs {
            margin: 0px;
            padding: 0px;
            list-style: none;
            box-sizing: border-box;
            display: flex;
            gap: 0px;
            align-items: end;
            font: var(--font-tab);
        }
    `;
}
