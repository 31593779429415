import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { ModalEditorResult } from "se-shared/utils/result";
import { DraftApi } from "../../services/draft.api";
import { ModalDialogService, ModelDialog } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { BaseEditor, ModalEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-publish-to-space-dialog")
export class PublishDialogElement extends LitElement implements ModelDialog {
    private _modalService: ModalDialogService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _draftApi: DraftApi;

    @query("#configName") private _configName: InputEditorElement;
    @query("#space") private _space: InputEditorElement;
    @query("#allowOverwrite") private _allowOverwrite: CheckboxEditorElement;

    private _data: any[];
    private _draftId: number;
    @state() private _defaultName: string;
    @state() private _defaultSpace = "0";
    @state() private _userSpaces: { id: number; name: string }[] = [];

    constructor() {
        super();

        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._draftApi = container.resolve(DraftApi);
    }

    async showDialog(options: { data: any[]; draftId: number; defaultName: string; defaultSpace: string }) {
        this._draftId = options.draftId;
        this._data = options.data;
        this._defaultName = options.defaultName;
        const spaces = await this._userState.getUserSpacesAsync();
        if (spaces.isOk) {
            this._userSpaces = [...spaces.value];
            this._userSpaces.splice(0, 0, { id: 0, name: "Home" });
            this._defaultSpace = this._userSpaces.find((p) => p.name === options.defaultSpace)?.id?.toString();
        } else {
            this._toasterService.showUnexpectedError(spaces.err.message);
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    updateChangedValues() {
        this.requestUpdate();
    }

    firstUpdated() {}

    private cancel(ev) {
        ev.preventDefault();
        this._modalService.close(false);
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const result = await this._draftApi.publishToSpaceAsync(
                this._draftId,
                parseInt(this._space.liveValue),
                this._configName.liveValue,
                this._allowOverwrite.liveValue
            );
            if (result.isOk) {
                this._data.splice(
                    this._data.findIndex((p) => p.id !== this._draftId),
                    1
                );
                await this._userState.updateDraftCount(this._data.length);
                this._userState.gridRowsChanged.triggerVoid();
                this._modalService.close(true);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <div style="font-weight: bold">Publish Draft</div>
                    <div class="icon" @click=${this.cancel}><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                </div>
                <div class="body">
                    <div class="container" id="editorContainer">
                        <div style="display: flex; flex-direction: column; gap: 10px">
                            <se-input-editor id="configName" required label="Config name" .value="${this._defaultName}"></se-input-editor>
                            <se-select-editor
                                required
                                id="space"
                                name="space"
                                type="text"
                                label="Select space"
                                labelPosition="top"
                                .value=${this._defaultSpace}
                                .options=${this._userSpaces}
                                width="100%"
                            ></se-select-editor>
                            <se-checkbox-editor id="allowOverwrite" label="Allow overwrite" labelPosition="right"></se-checkbox-editor>
                        </div>
                    </div>
                    <div class="savePanel">
                        <se-primary-button .action="${() => this.saveAsync()}" action-delay="500" text="Publish"></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            margin: auto;
            margin-top: 20vh;
            box-shadow: 4px 4px 4px rgb(80, 80, 80);
            font: var(--font);
            width: 350px;
        }
        .body {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            padding: 0px;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            color: black;
            border-radius: 5px 5px 0px 0px;
            border-bottom: solid 1px whitesmoke;
        }
        .icon {
            color: gray;
        }
        .icon:hover {
            color: darkred;
        }
        .container {
            height: 100%;
            min-height: 60px;
            overflow: auto;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
            border-radius: 3px 3px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            padding: 8px 5px 10px 50px;
        }
    `;
}
