import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import "../editors/checkbox-list-editor.element";

export type checkboxOption = { id: number, name: string, value: boolean, isNew?: boolean, tristate?: 'never' | 'always' | 'auto' };

@customElement('se-multi-select-editor')
export class MultiSelectEditor extends LitElement {

    @property()
    options: { id: number, name: string, value: boolean, isNew?: boolean, tristate?: 'never' | 'always' | 'auto' }[] = [];

    @property({ attribute: false, type: Array })
    selectedOptions: number[] = [];

    @property({ type: Boolean })
    dropdownOpen: boolean = false;

    @property({ type: String })
    optionsName: string = "Type";

    @property({ type: String })
    isSelectAll: boolean = false;

    private checkboxList: any;

    constructor() {
        super();
        this.selectedOptions = [];
    }
    get selectedValues() { return this.selectedOptions; }



    getSelectedValues() {
        this.checkboxList = this.shadowRoot?.querySelector('#checkboxList');
        const liveValue = this.checkboxList?.liveValue || [];

        this.selectedOptions = liveValue
            .filter((option) => option.liveValue)
            .map((option) => option.id);

        this.dispatchEvent(new CustomEvent('valueChanged', {
            detail: { selectedOptions: this.selectedOptions },
        }));

    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }
    getSelectedCount() {
        const liveValue = (this.checkboxList?.liveValue || this.options) || [];
        return liveValue.filter((option) => option.value || option.liveValue).length;
    }

    selectAll() {
        this.dispatchEvent(new CustomEvent('selectAll', {
            detail: { selectedOptions: this.selectedOptions },
        }));
    }
    clearAll() {
        this.dispatchEvent(new CustomEvent('clearAll', {
            detail: { selectedOptions: this.selectedOptions },
        }));
    }
    private onValueChanged() {
        this.getSelectedValues();
    }


    render() {
        return html`
      <div class="dropdown">
        <!-- Dropdown button -->
        <div class="dropdown-button" @click=${this.toggleDropdown}>
          ${this.optionsName} ${this.getSelectedCount() > 0 ? html`<span class="selected-count">${this.getSelectedCount()}</span>` : ''}
          <span class="arrow">&#9662;</span>
        </div>

        <!-- Dropdown content with existing checkbox list editor -->
        <div class="scroll-container dropdown-content ${this.dropdownOpen ? 'show' : ''}">
            ${this.isSelectAll ? html`<div class="action-buttons">
                <se-secondary-button @click=${this.selectAll} text="Select All"></se-secondary-button>
                <se-secondary-button @click=${this.clearAll} text="Clear All"></se-secondary-button>
            </div>
            <hr>` : ``}
            <se-checkbox-list-editor
              id="checkboxList"
              @valueChanged=${this.onValueChanged}
              .isShowSelectedFirst=${false}
              .value=${this.options}
            ></se-checkbox-list-editor>
        </div>
      </div>
    `;
    }


    static styles = css`
    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropdown-button {
      display: flex;
      align-items: center;
      padding: 1px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      background-color: #fff;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: white;
      min-width: 200px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      padding: 10px 0;
      z-index: 3;
      border-radius: 5px;
      border: 1px solid #ccc;
      max-height: 200px;
    }

    .dropdown-content.show {
      display: block;
    }

    .selected-count {
      font-size: 12px;
      margin-left: 5px;
      background-color: blue;
      color: white;
      padding: 2px 6px;
      border-radius: 12px;
    }

    .arrow {
      margin-left: auto;
    }
    .scroll-container {
        min-height: 0;
        overflow: auto;
        padding: 5px 10px 10px 10px;
        margin-top: 5px;
        background-color: white;
        box-sizing: border-box;
        max-height: 250px;
        max-width: 400px;
    }

    .action-buttons {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
    }

  `;
}
