import { Router } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { container } from "tsyringe";
import { AppConfigService } from "../services/app-config.service";
import { RouterService } from "../services/router-service";
import "./app-config/create-first-user.element";
import "./app-config/user-registration.element";
import "./change-password.element";
import "./forgot-password.element";
import "./home.element";
import "./labels/label-editor.element";
import "./labels/labels.element";
import "./login.element";
import "./reset-password.element";
import "./config-details/agent-details.element";
import "./config-details/shared-file-details.element";
import "./config-details/shared-file-create.element";
import "./config-details/shared-file-editor.element";
import "./run/agent-tasks.element";
import "./run/run-history.element";
import "./run/runs.element";
import "./run/all-runs.element";
import "./run/org-runs.element";
import "./billing/billing.element";
import "./billing/transactions.element";
import "./billing/usage.element";
import "./billing/agent-usage.element";
import "./run/start-run.element";
import "./run/start-many-runs.element";
import "./spaces/space-editor.element";
import "./spaces/space.element";
import "./spaces/spaces.element";
import "./users/organization-users.element";
import "./users/user-editor.element";
import "./mfa/multi-factor-authentication-setup.element";
import "./mfa/multi-factor-authentication.element";

@customElement("se-control-center")
export class ControlCenterElement extends LitElement {
    private _routerService: RouterService;
    private _appConfigService: AppConfigService;

    @query("#outlet") private _outlet: HTMLDivElement;

    constructor() {
        super();

        this._appConfigService = container.resolve(AppConfigService);
        this._routerService = container.resolve(RouterService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {
        this._routerService.initialize(this._outlet);
        if (this._appConfigService.isNew) {
            Router.go("/create-first-user");
        }
    }

    render() {
        return html` <div id="outlet" class="outlet"></div> `;
    }

    static styles = css`
        :host {
            display: block;
            background-color: var(--color-primary);
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
        }
        .outlet {
            height: 100%;
        }
    `;
}
