import { container, singleton } from "tsyringe";
import { idName } from "../models/id-name";
import { Organization } from "../models/organization";
import { OrganizationUsersViewModel } from "../models/organization-users-view-model";
import { OrganizationsViewModel } from "../models/organizations-view-model";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";

@singleton()
export class OrganizationApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<Organization>(`/api/organization/${id}`, this._authService.token);
    }
    async getAllAsync() {
        return this.getObjectAsync<Organization[]>(`/api/organization`, this._authService.token);
    }
    async getAllDetailsAsync(organizationRequest: any) {
        return this.postObjectReturnObjectAsync<OrganizationsViewModel>(
            `/api/organization/search`,
            organizationRequest,
            this._authService.token
        );
    }
    async getAllIdAndNamesAsync() {
        return this.getObjectAsync<idName[]>(`/api/organization/id-name`, this._authService.token);
    }
    async createAsync(name: string, isBillable: boolean, isMfaRequired: boolean, isAuditLog: boolean, isProxyRequired: boolean) {
        return this.postObjectAsync(
            `/api/organization`,
            { name: name, isBillable: isBillable, isMfaRequired: isMfaRequired, isAuditLog: isAuditLog, isProxyRequired: isProxyRequired },
            this._authService.token
        );
    }
    async updateAsync(
        id: number,
        name: string,
        isBillable: boolean,
        isMfaRequired: boolean,
        isAuditLog: boolean,
        isProxyRequired: boolean
    ) {
        return this.putObjectAsync(
            `/api/organization/${id}`,
            { name: name, isBillable: isBillable, isMfaRequired: isMfaRequired, isAuditLog: isAuditLog, isProxyRequired: isProxyRequired },
            this._authService.token
        );
    }
    async approveOrgAsync(approvalString: string) {
        return this.putObjectAsync(`/api/user/mail-approve`, approvalString, this._authService.token);
    }

    async deleteAllAsync(orgIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/organization/all`, { ids: orgIds }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/organization/${id}`, this._authService.token);
    }

    async approveOrgAdminsAsync(id: number) {
        return this.putAsync(`/api/organization/${id}/approve`, this._authService.token);
    }

    async getAllOrganizationUsersAsync(pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<OrganizationUsersViewModel>(
            `/api/organization/users?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }
}
