import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { UserState } from "../../services/user.state";
import "../components/fa-icon.element";
import "./run-audit-log.element";
import "./config-audit-log.element";
import "./account-audit-log.element";
import "./login-audit-log.element";
import "./system-audit-log.element";

type GridType = "configs" | "account" | "runs" | "logins" | "system";

@customElement("se-audit-log")
export class SeAuditLogElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _configService: ConfigService;

    private _configId?: number;
    @state() private _gridType: GridType = "configs";

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._configService = container.resolve(ConfigService);
    }

    async connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();

        if (this._configId && this._userState.selectedConfigId !== this._configId) {
            const result = await this._configService.api.getNameAndTypeAsync(this._configId);
            if (result.isOk) {
                this._userState.selectedConfigId = this._configId;
                this._userState.selectedConfigName = result.value.name;
                this._userState.selectedConfigType = result.value.type;
                this._userState.selectedConfigValidationStatus = result.value.validationStatus;
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    disconnectedCallback() {
        this.removeEventListener("agentValidationStatusChanged", (ev: CustomEvent) => this.onAgentValidationStatusChanged(ev));
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this._configId = parseInt(location.params.configId.valueOf() as string);
        }
        if (location.params.gridType) {
            this._gridType = location.params.gridType.valueOf() as GridType;
        }
    }

    private onAgentValidationStatusChanged(evt: CustomEvent) {
        this._userState.selectedConfigValidationStatus = evt.detail.validationStatus;
    }

    private goRuns() {
        this._gridType = "runs";
        window.history.pushState("", "", `/audit-log/runs`);
    }
    private goConfigs() {
        this._gridType = "configs";
        window.history.pushState("", "", `/audit-log/configs`);
    }
    private goLogins() {
        this._gridType = "logins";
        window.history.pushState("", "", `/audit-log/logins`);
    }
    private goAccount() {
        this._gridType = "account";
        window.history.pushState("", "", `/audit-log/account`);
    }
    private goSystem() {
        this._gridType = "system";
        window.history.pushState("", "", `/audit-log/system`);
    }

    render() {
        return html`
            <div style="display: flex; border-bottom: solid 1px gray;padding-bottom: 5px; align-items: end; overflow-x:clip;">
                <span class="tabItems ${this._gridType === "configs" ? `active` : ``}" @click=${this.goConfigs}>Configs</span>
                <span class="tabItems ${this._gridType === "account" ? `active` : ``}" @click=${this.goAccount}>Account</span>
                <span class="tabItems ${this._gridType === "runs" ? `active` : ``}" @click=${this.goRuns}>Runs</span>
                <span class="tabItems ${this._gridType === "logins" ? `active` : ``}" @click=${this.goLogins}>Logins</span>
                ${this._authService.isSE4Admin
                    ? html`<span class="tabItems ${this._gridType === "system" ? `active` : ``}" @click=${this.goSystem}>System</span>`
                    : html``}
            </div>
            <div style="flex:1; min-height:0">
                ${this._gridType === "configs" ? html`<se-config-audit-log></se-config-audit-log>` : ``}
                ${this._gridType === "account" ? html`<se-account-audit-log></se-account-audit-log>` : ``}
                ${this._gridType === "runs" ? html`<se-run-audit-log></se-run-audit-log>` : ``}
                ${this._gridType === "logins" ? html`<se-login-audit-log></se-login-audit-log>` : ``}
                ${this._gridType === "system" ? html`<se-system-audit-log></se-system-audit-log>` : ``}
            </div>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            gap: 20px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .tabItems {
            padding: 0px 10px 0px 10px;
            cursor: pointer;
            margin-bottom: -5px;
            padding-bottom: 5px;
        }
        .active {
            border-bottom: 5px solid black;
            font-weight: 900;
            margin-bottom: -10px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
