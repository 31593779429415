import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { MenuService } from "../../services/menu.service";
import { UserState } from "../../services/user.state";
import { MenuDialog } from "../components/menu-template";
import { BaseEditor } from "../editors/base-editor";
import "../editors/checkbox-list-editor.element";
import "../editors/input-editor.element";
import { SelectEditorElement } from "../editors/select-editor.element";

@customElement("se-move-configs")
export class MoveConfigsElement extends LitElement implements MenuDialog {
    private _userState: UserState;
    private _configService: ConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _menuService: MenuService;

    @state() private _space = "";

    private _selectedRows: any[];
    @state() private _userSpaces: { id: number; name: string }[] = [];
    private _callback?: () => void;

    @query("#space") private _spaceEditor: SelectEditorElement;

    constructor() {
        super();

        this._userState = container.resolve(UserState);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._configService = container.resolve(ConfigService);
        this._menuService = container.resolve(MenuService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async showMenu(input: { selectedRows: any[]; callback?: () => void }) {
        this._callback = input.callback;
        this._selectedRows = input.selectedRows;
        const spaces = await this._userState.getUserSpacesAsync();
        if (spaces.isOk) {
            this._userSpaces = [...spaces.value];
            this._userSpaces.splice(0, 0, { id: 0, name: "Home" });
            if (this._userState.selectedSpaceId !== undefined) {
                this._userSpaces = this._userSpaces.filter((p) => p.id !== this._userState.selectedSpaceId);
            }
        } else {
            this._toasterService.showUnexpectedError(spaces.err.message);
        }
    }

    async firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async onMove() {
        const result = await this._configService.api.moveToSpaceAsync(
            parseInt(this._space),
            this._selectedRows.map((p) => p.id as number)
        );
        if (result.isOk) {
            if (this._callback) {
                this._callback();
            } else {
                this._userState.gridDataChanged.triggerVoid();
            }
            this._menuService.closeMenu();
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    private async onSpaceChanged() {
        this._space = this._spaceEditor.liveValue;
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <span
                        >Move
                        ${this._selectedRows.length === 1
                            ? html`<span style="font-weight: bold">${this._selectedRows[0].name}</span>`
                            : html`${this._selectedRows.length} configs`}
                        to:</span
                    >
                </div>
                <div class="body">
                    <div class="scroll-container" id="editorContainer">
                        <se-select-editor
                            required
                            @valueChanged=${this.onSpaceChanged}
                            id="space"
                            name="space"
                            type="text"
                            placeholder="Select Space"
                            labelPosition="top"
                            .options=${this._userSpaces}
                            input-width="100%"
                        ></se-select-editor>
                    </div>
                    <div class="savePanel" style=${styleMap({ display: this._space ? "flex" : "none" })}>
                        <se-primary-button .action="${() => this.onMove()}" action-delay="500" text="Move"></se-primary-button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            font: var(--font);
            box-sizing: border-box;
            background-color: var(--color-light);
            box-shadow: 2px 2px 2px 2px Gray;
            border-radius: 2px 2px 2px 2px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
        }
        .body {
            border-top: solid 1px lightgray;
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            width: 100%;
            min-width: 200px;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px 5px 1px 5px;
        }
        .icon {
            position: absolute;
            right: 15px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 15px 10px 15px 10px;
            background-color: white;
            max-height: 250px;
            max-width: 400px;
        }
        .savePanel {
            border-top: solid 1px lightgray;
            display: flex;
            flex-direction: rows;
            justify-content: end;
            padding: 8px 5px 10px 5px;
        }
    `;
}
