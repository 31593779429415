import { Router } from "@vaadin/router";
import { ErrorSeverity } from "se-shared/enums/error-severity";
import { ok, err, errVoid, NetworkError, NetworkResult, VoidNetworkResult, okVoid } from "se-shared/utils/result";

export class BaseApi {
    deleteObjectAsync(input: RequestInfo, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: "DELETE", authToken: authToken });
    }
    deleteAllObjectsAsync(input: RequestInfo, body: any, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: "DELETE", body: body, authToken: authToken });
    }
    getObjectAsync<T>(input: RequestInfo, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequest(input, { method: "GET", authToken: authToken });
    }
    postObjectReturnObjectAsync<T>(input: RequestInfo, body: any, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequest(input, { method: "POST", body: body, authToken: authToken });
    }
    putObjectReturnObjectAsync<T>(input: RequestInfo, body: any, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequest(input, { method: "PUT", body: body, authToken: authToken });
    }
    postObjectAsync(input: RequestInfo, body: any, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: "POST", body: body, authToken: authToken });
    }
    putObjectAsync(input: RequestInfo, body: any, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: "PUT", body: body, authToken: authToken });
    }
    postAsync(input: RequestInfo, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: "POST", authToken: authToken });
    }
    putAsync(input: RequestInfo, authToken?: string): Promise<VoidNetworkResult> {
        return this.sendRequestVoid(input, { method: "PUT", authToken: authToken });
    }
    getBlobAsync<T>(input: RequestInfo, authToken?: string): Promise<NetworkResult<T>> {
        return this.sendRequestBlob(input, { method: "GET", authToken: authToken });
    }
    uploadFileAsync(
        input: RequestInfo,
        file: File,
        authToken?: string,
        loadstartCallback?: any,
        progressCallback?: any
    ): Promise<VoidNetworkResult> {
        //return this.uploadFiles(input, { method: "POST", files: [file], authToken: authToken });
        return this.uploadFilesWithProgress(
            input,
            { method: "POST", files: [file], authToken: authToken },
            loadstartCallback,
            progressCallback
        );
    }
    uploadFileListAsync(
        input: RequestInfo,
        files: FileList,
        authToken?: string,
        loadstartCallback?: any,
        progressCallback?: any
    ): Promise<VoidNetworkResult> {
        //return this.uploadFiles(input, { method: "POST", files: Array.from(files), authToken: authToken });
        return this.uploadFilesWithProgress(
            input,
            { method: "POST", files: Array.from(files), authToken: authToken },
            loadstartCallback,
            progressCallback
        );
    }

    sendRequest<T>(input: RequestInfo, opt: { method: string; body?: any; authToken?: string }): Promise<NetworkResult<T>> {
        return fetch(input, {
            method: opt.method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...(opt.authToken && { Authorization: "bearer " + opt.authToken }),
            },
            ...(opt.body && { body: JSON.stringify(opt.body) }),
        }).then((response) => {
            if (response.ok)
                return response.json().then((result) => {
                    return ok(result);
                });
            else if (response.status === 401) {
                return response.text().then((text) => {
                    if (!window.location.href.includes("/login")) {
                        //only redirect if not already on login page
                        window.location.href = "/login?message=Access+denied";
                    }
                    try {
                        const e = JSON.parse(text);
                        return err(new NetworkError(e.message, e.severity, e.statusCode));
                    } catch {
                        return err(new NetworkError(text, response.status));
                    }
                });
            } else if (response.status === 404) {
                return err(new NetworkError(opt.method + " " + input.toString() + " Not Found", ErrorSeverity.Error, response.status));
            } else if (response.status === 405) {
                return err(
                    new NetworkError(
                        `${input.toString()} not accessible using ${opt.method}. ${opt.method} not allowed.`,
                        ErrorSeverity.Error,
                        response.status
                    )
                );
            } else if (response.status === 504) {
                return err(new NetworkError("Request timeout.", ErrorSeverity.Error, response.status));
            } else
                return response.text().then((text) => {
                    try {
                        const e = JSON.parse(text);
                        return err(new NetworkError(e.message, e.severity, e.statusCode));
                    } catch {
                        return err(new NetworkError(text, ErrorSeverity.Error, response.status));
                    }
                });
        });
    }
    sendRequestVoid(input: RequestInfo, opt: { method: string; body?: any; authToken?: string }): Promise<VoidNetworkResult> {
        return fetch(input, {
            method: opt.method,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...(opt.authToken && { Authorization: "bearer " + opt.authToken }),
            },
            ...(opt.body && { body: JSON.stringify(opt.body) }),
        }).then((response) => {
            if (response.ok) return okVoid();
            else if (response.status === 401) return errVoid(new NetworkError("Access denied", ErrorSeverity.Error, response.status));
            else if (response.status === 404) {
                return errVoid(new NetworkError(opt.method + " " + input.toString() + " Not Found", ErrorSeverity.Error, response.status));
            } else
                return response.text().then((text) => {
                    try {
                        const err = JSON.parse(text);
                        return errVoid(new NetworkError(err.message, err.severity, err.statusCode));
                    } catch {
                        return errVoid(new NetworkError(text, ErrorSeverity.Error, response.status));
                    }
                });
        });
    }

    sendRequestBlob(input: RequestInfo, opt: { method: string; body?: any; authToken?: string }): Promise<NetworkResult<any>> {
        return fetch(input, {
            method: opt.method,
            headers: {
                Accept: "application/octet-stream",
                "Content-Type": "application/octet-stream",
                ...(opt.authToken && { Authorization: "bearer " + opt.authToken }),
            },
            ...(opt.body && { body: JSON.stringify(opt.body) }),
        }).then((response) => {
            if (response.ok) return response.blob().then((result) => ok(result));
            else if (response.status === 401) {
                return err(new NetworkError("Access denied", ErrorSeverity.Error, response.status));
            } else if (response.status === 504) {
                return err(new NetworkError("Request timeout.", ErrorSeverity.Error, response.status));
            } else if (response.status === 404) {
                return err(new NetworkError(opt.method + " " + input.toString() + " Not Found", ErrorSeverity.Error, response.status));
            } else
                return response.text().then((text) => {
                    try {
                        const error = JSON.parse(text);
                        if (error.message) return err(new NetworkError(error.message, error.severity, error.statusCode));
                        else if (error.errors) return err(new NetworkError(JSON.stringify(error.errors), ErrorSeverity.Error, error.statusCode));
                        else return err(new NetworkError(text, ErrorSeverity.Error, response.status));
                    } catch (exp) {
                        return err(new NetworkError(text, ErrorSeverity.Error, response.status));
                    }
                });
        });
    }

    uploadFiles(input: RequestInfo, opt: { method: string; files?: File[]; authToken?: string }): Promise<VoidNetworkResult> {
        const formData = new FormData();
        opt.files.forEach((p) => formData.append("file", p));

        return fetch(input, {
            method: opt.method,
            headers: {
                //Accept: "application/json",
                //"Content-Type": "application/json",
                ...(opt.authToken && { Authorization: "bearer " + opt.authToken }),
            },
            ...{ body: formData },
        }).then((response) => {
            if (response.ok) return okVoid();
            else if (response.status === 401) return errVoid(new NetworkError("Access denied", ErrorSeverity.Error, response.status));
            else if (response.status === 404) {
                return errVoid(new NetworkError(opt.method + " " + input.toString() + " Not Found", ErrorSeverity.Error, response.status));
            } else
                return response.text().then((text) => {
                    try {
                        const err = JSON.parse(text);
                        return errVoid(new NetworkError(err.message, err.severity, err.statusCode));
                    } catch {
                        return errVoid(new NetworkError(text, ErrorSeverity.Error, response.status));
                    }
                });
        });
    }

    makeUploadRequest(url, opt: { method: string; files?: File[]; authToken?: string }, loadstartCallback: any, progressCallback: any) {
        return new Promise(function (resolve, reject) {
            const formData = new FormData();
            opt.files.forEach((p) => formData.append("file", p));

            const xhr = new XMLHttpRequest();
            xhr.open(opt.method, url);

            if (opt.authToken) {
                xhr.setRequestHeader("Authorization", "bearer " + opt.authToken);
            }

            if (loadstartCallback) {
                xhr.upload.addEventListener("loadstart", loadstartCallback, false);
            }

            if (progressCallback) {
                //xhr.upload.onprogress = progressCallback || null;
                xhr.upload.addEventListener("progress", progressCallback, false);
                //xhr.addEventListener("progress", progressCallback);
            }

            xhr.onload = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    // In local files, status is 0 upon success in Mozilla Firefox
                    if (xhr.status === 0 || (xhr.status >= 200 && xhr.status < 300)) {
                        resolve(xhr.response);
                    } else {
                        reject({
                            statusCode: xhr.status,
                            statusText: xhr.statusText,
                        });
                    }
                }
            };
            xhr.onerror = function () {
                reject({
                    statusCode: xhr.status,
                    statusText: xhr.statusText,
                });
            };
            xhr.send(formData);
        });
    }

    uploadFilesWithProgress(
        input: RequestInfo,
        opt: { method: string; files?: File[]; authToken?: string },
        loadstartCallback: any,
        progressCallback: any
    ): Promise<VoidNetworkResult> {
        return this.makeUploadRequest(input.toString(), opt, loadstartCallback, progressCallback)
            .then((response) => {
                return okVoid<NetworkError>();
            })
            .catch((err) => {
                if (err.statusCode === 400) return errVoid(new NetworkError("Bad Request", err.statusCode));
                if (err.statusCode === 401) return errVoid(new NetworkError("Access denied", err.statusCode));
                if (err.statusCode === 404)
                    return errVoid(new NetworkError(opt.method + " " + input.toString() + " Not Found", err.statusCode));
                else return errVoid(new NetworkError("Unknown Error", err.statusCode));
            });
    }
}
