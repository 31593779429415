import { Router } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ConfigType } from "../../enums/config-type";
import { RunStatus } from "../../enums/run-status";
import { ConfigSearchResultModel } from "../../models/config-search-result";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { ServerService } from "../../services/server.service";
import { UserState } from "../../services/user.state";

@customElement("se-space-search-editor")
export class SeComponent extends LitElement {
    private _configService: ConfigService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _serverService: ServerService;
    private _authService: AuthService;

    @state() private thisSpaceOptions?: ConfigSearchResultModel[] = [];
    @state() private allSpaceOptions?: ConfigSearchResultModel[] = [];
    @state() private _opened = false;
    @state() private _loading = false;
    private _highlightedElement: HTMLTableRowElement;
    private _query: string;
    private _lastInputValue: string;

    private _keyTimeout: NodeJS.Timeout;

    @query("#defaultInput") private _inputElement: HTMLInputElement;
    @query("#suggestions") private _suggestionElement: HTMLUListElement;

    constructor() {
        super();
        this._configService = container.resolve(ConfigService);
        this._userState = container.resolve(UserState);
        this._serverService = container.resolve(ServerService);
        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
    }

    _onKeyDown(ev) {
        switch (ev.key) {
            case "ArrowUp":
                ev.preventDefault();
                ev.stopPropagation();
                this._markPreviousElement();
                break;
            case "ArrowDown":
                ev.preventDefault();
                ev.stopPropagation();
                this._markNextElement();
                break;
            case "Tab":
            case "Enter":
                if (this._opened) {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this._highlightedElement && this._highlightedElement.dispatchEvent(new MouseEvent("mousedown"));
                }
                break;
            case "Escape":
                this.close();
                this.blur();
                ev.stopPropagation();
                break;
        }
    }

    private _onKeyUp(ev) {
        switch (ev.key) {
            case "ArrowLeft":
            case "ArrowRight":
            case "Tab":
            case "Escape":
                break;
            case "ArrowUp":
                ev.preventDefault();
                ev.stopPropagation();
                break;
            case "ArrowDown":
                ev.preventDefault();
                ev.stopPropagation();
                break;
            case "Enter":
                this._highlightedElement && this._highlightedElement.dispatchEvent(new MouseEvent("mousedown"));
                this.blur();
                break;
            default:
                if (this._inputElement.value) {
                    if (this._lastInputValue !== this._inputElement.value) {
                        this._lastInputValue = this._inputElement.value;
                        const query = this._inputElement.value.trim();
                        if (query.length > 0) {
                            this._searchPrepareAsync(query);
                        } else {
                            if (this._opened) this.close();
                        }
                    }
                } else {
                    if (this._opened) this.close();
                }
        }
    }

    private _searchPrepareAsync(searchTerm: string) {
        const status = [];
        const configType = [];
        const words = searchTerm.split(" ");
        let query = "";
        words.forEach((word) => {
            if (word.startsWith(":")) {
                const op = word.substr(1).toLowerCase();
                switch (op) {
                    case "file":
                    case "data":
                        configType.push(ConfigType.SharedFile);
                        break;
                    case "agent":
                        configType.push(ConfigType.Agent);
                        break;
                    case "template":
                        configType.push(ConfigType.Template);
                        break;
                    case "active":
                        status.push(RunStatus.waiting);
                        status.push(RunStatus.queuing);
                        status.push(RunStatus.running);
                        status.push(RunStatus.exporting);
                        status.push(RunStatus.stopping);
                        break;
                    case "running":
                    case "run":
                        status.push(RunStatus.running);
                        status.push(RunStatus.exporting);
                        break;
                    case "export":
                    case "exporting":
                        status.push(RunStatus.exporting);
                        break;
                    case "queu":
                    case "queuing":
                        status.push(RunStatus.queuing);
                        break;
                    case "waiting":
                        status.push(RunStatus.waiting);
                        break;
                    case "fail":
                    case "failed":
                    case "failure":
                        status.push(RunStatus.failed);
                        status.push(RunStatus.failure);
                        break;
                    case "success":
                        status.push(RunStatus.success);
                        break;
                    case "complete":
                    case "completed":
                        status.push(RunStatus.completed);
                        break;
                    case "stop":
                    case "stopping":
                    case "stopped":
                        status.push(RunStatus.stopping);
                        status.push(RunStatus.stopped);
                        break;
                }
            } else {
                if (query.length > 0) query += " ";
                query += word;
            }
        });

        if (query.length > 0 || configType.length > 0 || status.length > 0) {
            if (this._keyTimeout) clearTimeout(this._keyTimeout);
            this.loading();
            this._keyTimeout = setTimeout(() => this._searchAsync(query.toLowerCase(), configType, status), query.length > 2 ? 200 : 500);
        }
    }

    private async _searchAsync(query: string, configType?: number[], status?: number[]) {
        const result = await this._configService.api.getSearchResultAsync(query, configType, status);
        if (result.isOk) {
            this.thisSpaceOptions = result.value
                .filter((p) => (p.spaceId ?? 0) === this._userState.selectedSpaceId)
                .sort((a, b) => a.name.localeCompare(b.name));
            this.allSpaceOptions = result.value
                .filter((p) => (p.spaceId ?? 0) !== this._userState.selectedSpaceId)
                .sort((a, b) => a.name.localeCompare(b.name));
            this._query = query;
            this.open();
        }
    }

    _markPreviousElement() {
        if (!this._highlightedElement) {
            this._setHighlightedElement();
            return;
        }

        if (this._highlightedElement.previousElementSibling.classList.contains("rowHeader")) {
            if (this._highlightedElement?.previousElementSibling?.previousElementSibling instanceof HTMLTableRowElement) {
                this._highlightedElement.classList.remove("active");
                this._highlightedElement = this._highlightedElement?.previousElementSibling?.previousElementSibling;
                this._highlightedElement.classList.add("active");
                this._highlightedElement.previousElementSibling.scrollIntoView();
            } else {
                this._highlightedElement.previousElementSibling.scrollIntoView();
            }
            return;
        }

        this._highlightedElement.classList.remove("active");
        if (this._highlightedElement.previousElementSibling instanceof HTMLTableRowElement) {
            this._highlightedElement = this._highlightedElement.previousElementSibling;

            this._highlightedElement.classList.add("active");
            this._highlightedElement.previousElementSibling.scrollIntoView();
        }
    }

    _markNextElement() {
        if (!this._highlightedElement) {
            this._setHighlightedElement();
            return;
        }

        if (!this._highlightedElement.nextElementSibling) {
            return;
        }

        this._highlightedElement.classList.remove("active");
        if (this._highlightedElement.nextElementSibling instanceof HTMLTableRowElement) {
            this._highlightedElement = this._highlightedElement.nextElementSibling;
            if (
                this._highlightedElement.classList.contains("rowHeader") &&
                this._highlightedElement.nextElementSibling instanceof HTMLTableRowElement
            ) {
                this._highlightedElement = this._highlightedElement.nextElementSibling;
            }
            this._highlightedElement.classList.add("active");
            this._highlightedElement.previousElementSibling.scrollIntoView();
        }
    }

    /*private async _onFocusAsync() {
        if (!this._opened) {
            if (!this.textOptions) {
                this.textOptions = await this.getTextOptions();
            }
            if (this.textOptions?.length) {
                this.open();
                //this.suggest(this.getSuggestions(), true);
                //this.suggest(this.textOptions, true);
            }
        }
    }*/

    _onBlur() {
        this.close();
    }

    private _setHighlightedElement() {
        if (this._highlightedElement) {
            this._highlightedElement.classList.remove("active");
            this._highlightedElement = null;
        }
        const elem = this._suggestionElement?.children[0]?.children[0];
        if (elem instanceof HTMLTableRowElement) {
            this._highlightedElement = elem;
            if (
                this._highlightedElement.classList.contains("rowHeader") &&
                this._highlightedElement.nextElementSibling instanceof HTMLTableRowElement
            ) {
                this._highlightedElement = this._highlightedElement.nextElementSibling;
            }
            if (this._highlightedElement) this._highlightedElement.classList.add("active");
        }
    }

    async loading() {
        this._opened = true;
        this._loading = true;
        await this.updateComplete;
        this.onResize();
    }

    async open() {
        this._loading = false;
        this._suggestionElement.style.top = "0px";
        this._suggestionElement.style.left = "0px";
        this._opened = true;
        await this.updateComplete;
        this.onResize();

        for (const item of Array.from(this._suggestionElement.children)) {
            item.classList.remove("active");
        }
        this._setHighlightedElement();
    }
    async close() {
        if (this._keyTimeout) clearTimeout(this._keyTimeout);
        this.thisSpaceOptions = [];
        this.allSpaceOptions = [];
        this._opened = false;
        this._inputElement.value = "";
        this._lastInputValue = "";
        await this.updateComplete;
        if (this._highlightedElement) this._highlightedElement.classList.remove("active");
        this._highlightedElement = null;
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("resize", this._handleResize);
    }
    disconnectedCallback() {
        window.removeEventListener("resize", this._handleResize);
        super.disconnectedCallback();
    }

    private _handleResize = () => this.onResize();

    private onResize() {
        const contentRec = this._inputElement.getBoundingClientRect();

        if (contentRec.right > 500) {
            this._suggestionElement.style.width = "500px";
        } else if (contentRec.right < window.innerWidth - 50) {
            this._suggestionElement.style.width = contentRec.right - 50 + "px";
        } else if (contentRec.left > window.innerWidth - 50) {
            this.close();
            return;
        } else {
            this._suggestionElement.style.width = window.innerWidth - 100 + "px";
        }
        const seggestRec = this._suggestionElement.getBoundingClientRect();

        if (contentRec.right < window.innerWidth - 50) {
            this._suggestionElement.style.left = contentRec.right - seggestRec.width + "px";
        } else {
            this._suggestionElement.style.left = "50px";
        }

        let idealHeight = 500;
        if (this.thisSpaceOptions.length + this.allSpaceOptions.length < 5) {
            idealHeight = 175;
        } else if (this.thisSpaceOptions.length + this.allSpaceOptions.length < 10) {
            idealHeight = 350;
        }

        this._suggestionElement.style.top = contentRec.bottom + "px";
        if (contentRec.bottom + idealHeight < window.innerHeight - 50) {
            this._suggestionElement.style.height = idealHeight + "px";
        } else if (window.innerHeight - contentRec.bottom - 50 < 50) {
            this.close();
            return;
        } else {
            this._suggestionElement.style.height = window.innerHeight - contentRec.bottom - 50 + "px";
        }
    }

    highlightChars(item) {
        if (this._query && this._query.length > 2) {
            const index = item.toLowerCase().indexOf(this._query);
            if (index > -1) {
                return html`${item.substring(0, index)}<b>${item.substring(index, index + this._query.length)}</b>${item.substring(
                    index + this._query.length
                )}`;
            }
        } else if (this._query && this._query.length > 0 && item.toLowerCase().startsWith(this._query)) {
            return html`<b>${item.substring(0, this._query.length)}</b>${item.substring(this._query.length)}`;
        }
        return item;
    }

    private _getIcon(configType: number) {
        return choose(
            configType,
            [
                [ConfigType.Agent, () => html`<fa-icon class="icon" fa-class="far fa-robot"></fa-icon>`],
                [ConfigType.Template, () => html`<fa-icon class="icon" fa-class="far fa-cubes"></fa-icon>`],
                [ConfigType.SharedFile, () => html`<fa-icon class="icon" fa-class="far fa-file"></fa-icon>`],
            ],
            () => html`${configType}`
        );
    }

    private _getTypeName(configType: ConfigType) {
        return configType === ConfigType.Agent ? "agent" : configType === ConfigType.Template ? "template" : "file";
    }

    private async _gotoSpace(spaceId: number, evt: MouseEvent) {
        evt.stopPropagation();
        evt.preventDefault();
        this._userState.selectedSpaceId = spaceId ?? 0;
        this._userState.selectedLabelId = 0;
        await this._userState.selectedSpaceOrLabelChanged.triggerVoidAwait();
        Router.go(`/space/${spaceId ?? 0}/label/0`);
        this.close();
    }

    private _gotoConfigDetails(item: ConfigSearchResultModel, evt: MouseEvent) {
        evt.stopPropagation();
        evt.preventDefault();
        this._userState.selectedSpaceId = item.spaceId ?? 0;
        this._userState.selectedLabelId = 0;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        if (item.configType === ConfigType.SharedFile) {
            Router.go(`/space/${item.spaceId ?? 0}/shared-file/${item.id}/details`);
        } else {
            Router.go(`/space/${item.spaceId ?? 0}/config/${item.id}/details/runs`);
        }
        this.close();
    }

    private async _openEditor(
        input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number },
        evt: MouseEvent
    ): Promise<boolean> {
        evt.stopPropagation();
        evt.preventDefault();

        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        const response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return;
        } else {
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        //We need to make sure we return to a label that exists, since we may return to a different space that doesn't have the current label available.
        this._userState.selectedLabelId = 0;

        //this.dispatchEvent(new CustomEvent("startloading", { bubbles: true, composed: true, detail: {} }));
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);
        //form.setAttribute("target", "Sequentum Enterprise Editor");

        const params = {
            ...{
                redirectUrl: window.location.origin + `/space/${input.spaceId ?? 0}/config/${input.configId}/details/info`, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        this.close();
    }

    render() {
        return html`<input
                id="defaultInput"
                type="text"
                name="search"
                placeholder="Search.."
                @blur=${this._onBlur}
                @keydown=${this._onKeyDown}
                @keyup=${this._onKeyUp}
            />
            <div class="searchPanel" ?hidden=${!this._opened} id="suggestions">
                ${this._loading
                    ? html`<div  class="centerContent"> <fa-icon
                        style="font-size:0.9em"
                        fa-class="far fa-spinner fa-spin">
                    </fa-icon>&nbsp;&nbsp;<span>Loading...</<span></div>`
                    : html` ${this.thisSpaceOptions.length === 0 && this.allSpaceOptions.length === 0
                          ? html`<div class="centerContent"><span>No results found.</<span></div>`
                          : html`<table style="width:100%;border-collapse: collapse; font: var(--font-menu);">
                                ${this.thisSpaceOptions.length > 0
                                    ? html` <tr class="rowHeader">
                                              <th colspan="4">Results in ${this.thisSpaceOptions[0].spaceName ?? "Private"}</th>
                                          </tr>
                                          ${this.thisSpaceOptions.map(
                                              (item) =>
                                                  html`<tr class="row" @mousedown=${(evt) => this._gotoConfigDetails(item, evt)}>
                                                      <td class="ticon">${this._getIcon(item.configType)}</td>
                                                      <td class="name" colspan="2"><span>${this.highlightChars(item.name)}</span></td>
                                                      <td class="aicon">
                                                          ${item.configType !== ConfigType.SharedFile
                                                              ? html`<span @mousedown=${(evt) => this._openEditor({ configId: item.id, configType: item.configType, spaceId: item.spaceId ?? 0 }, evt)} ${htmlTitle(`Edit ${this._getTypeName(item.configType)}.`)}><fa-icon class="icon" fa-class="far fa-pen-to-square"></span>`
                                                              : html``}
                                                      </td>
                                                  </tr>`
                                          )}`
                                    : html``}
                                ${this.allSpaceOptions.length > 0
                                    ? html` <tr class="rowHeader">
                                              <th colspan="4">Results in other spaces</th>
                                          </tr>
                                          ${this.allSpaceOptions.map(
                                              (item) =>
                                                  html`<tr class="row" @mousedown=${(evt) => this._gotoConfigDetails(item, evt)}>
                                                      <td class="ticon">${this._getIcon(item.configType)}</td>
                                                      <td class="name"><span>${this.highlightChars(item.name)}</span></td>
                                                      <td class="space">
                                                          <span
                                                              @mousedown=${(evt) => this._gotoSpace(item.spaceId, evt)}
                                                              ${htmlTitle("Go to space")}
                                                              >${item.spaceName ?? "Private"}</span
                                                          >
                                                      </td>
                                                      <td class="aicon">
                                                          ${item.configType !== ConfigType.SharedFile
                                                              ? html`<span @mousedown=${(evt) => this._openEditor({ configId: item.id, configType: item.configType, spaceId: item.spaceId ?? 0 }, evt)} ${htmlTitle(`Edit ${this._getTypeName(item.configType)}.`)}><fa-icon class="icon" fa-class="far fa-pen-to-square"></span>`
                                                              : html``}
                                                      </td>
                                                  </tr>`
                                          )}`
                                    : html``}
                            </table>`}`}
            </div>`;
    }

    static styles = css`
        :host {
            display: block;
        }
        :host([hidden]) {
            display: none;
        }
        .centerContent {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        input[type="text"] {
            width: 170px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 4px;
            font: var(--font-input);
            background-color: white;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" fill="gray" xml:space="preserve"><path d="m16.436 15.085 3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406M10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11"/></svg>');
            background-size: 20px 20px;
            background-position: 2px 2px;
            background-repeat: no-repeat;
            padding: 3px 2px 2px 30px;
        }
        .searchPanel {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
            z-index: 900;
            background: white;
            display: block;
            list-style-type: none;
            border-left: 1px solid grey;
            border-top: 1px solid lightgrey;
            box-shadow: lightgrey 2px 2px 2px;
            border-radius: 2px;
            overflow: auto;
            border-radius: 4px;
        }
        .searchPanel.position-top {
            border-top: 1px solid grey;
        }

        .row {
            cursor: pointer;
        }

        .row > td {
            padding: 4px 4px 4px 6px;
            overflow-wrap: anywhere;
        }

        .ticon {
            width: 16px;
            color: gray;
            text-align: center;
        }
        .row.active > td.ticon {
            color: gainsboro;
        }
        td.aicon {
            font-size: 0.9rem;
            width: 16px;
            color: gray;
        }

        .row.active > td.aicon {
            color: gainsboro;
        }

        td.aicon > span:hover {
            color: black;
        }
        .row.active > td.aicon > span:hover {
            color: white;
        }

        .name > span:hover {
        }

        .space {
            color: gray;
        }
        .row.active > .space {
            color: gainsboro;
        }
        .space > span:hover {
            color: black;
        }
        .row.active > .space > span:hover {
            color: white;
        }

        .rowHeader {
            background-color: #f2f2f2;
        }
        .rowHeader > th {
            padding: 2px 6px 2px 6px;
            font-size: 0.82rem;
        }

        .row:hover {
            outline: 1px solid var(--color-secondary);
            outline-offset: -2px;
            border-radius: 3px;
        }
        .row.active {
            color: white;
            background-color: var(--color-secondary);
        }
        [hidden] {
            display: none;
        }
    `;
}
