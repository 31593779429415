import { LitElement, html } from 'lit-element';
import { customElement, property, query } from 'lit/decorators.js';
declare const google: any;
import { DestinationService } from '../../services/destination.service';
import { container } from 'tsyringe';
import googleSigninLight from '../../../../assets/google_signin_light.svg';

var oAuthWindow
@customElement('se-google-oauth-button')
export class GoogleOauthButton extends LitElement {
    private _destinationService: DestinationService;

    constructor() {
        super();
        this._destinationService = container.resolve(DestinationService);
    }

    render() {
        return html`

      <div id="buttonDiv">
        <div style="background-color:" id="google-signin"></div>
        <input @click=${this.openSignIn}  type="image" src=${googleSigninLight} style="box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);border-radius: 7px;height: 32px;"></input>

      </div>
    `;
    }

    firstUpdated() {
        window.addEventListener('message', function (event) {
            var res = event.data;
            if (res.message == "oauthResponse") {
                console.log(res.tempId);
                oAuthWindow.close();
            }
        });
    }
    
    async openSignIn() {
        var res = await this._destinationService.api.getGoogleOauthLink()
        if (res.isOk) {
            oAuthWindow = window.open(res.value, '_blank');
        } else {
            alert("Could not get google link.")
        }
    }
    async onSignIn(response: any) {
        console.log('ID Token: ' + response.credential);


        var idToken = response.credential;

        var res = await this._destinationService.api.callbackgoogle(idToken)
        console.log(res)
    }

}

