import { LitElement, html, css } from "lit";
import { customElement, property, query, queryAll, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";
import { SortOrder } from "se-shared/enums/sort-order";
import { DataGridColumn, DataGridTemplate } from "./data-grid-template";
import { MenuService } from "../../services/menu.service";
import { container } from "tsyringe";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { BaseEditor, SimpleEditor } from "../editors/base-editor";
import "se-shared/elements/editors/inline/input-editor.element";
import "se-shared/elements/editors/inline/select-editor.element";
import "../editors/inline/textarea-editor.element";
import "../editors/inline/number-editor.element";
import "se-shared/elements/editors/inline/checkbox-editor.element";
import "se-shared/elements/editors/inline/typeahead-editor.element";

@customElement("se-data-grid")
export class SeDataGrid extends LitElement {
    @property({ type: Array }) rows: any[] = [];
    @property({ type: Array }) columns: DataGridColumn[];
    @property({ type: Number }) pageIndex? = 1;
    @property({ type: Number }) recordsPerPage? = 100;
    @property() styles: any = [];
    @property({ type: Boolean }) fullHeight = true;
    @property({ type: Number }) footerHeight = 10;
    @property({ type: Boolean }) selectable = false;
    @property({ type: Boolean }) selectAllCheckbox = false;
    @property({ type: Boolean }) hasPreSelected = false;
    @property({ type: Boolean }) isAllSelected = false;
    @property({ attribute: "save-mode" }) saveMode: "all" | "cell" = "cell";
    @property({ attribute: "layout" }) layout: "normal" | "compact" = "normal";
    @property({ attribute: "new-button" }) newButton: "none" | "top" | "bottom" = "none";
    @property({ type: Object }) newRow?: (() => unknown) | (() => Promise<unknown>);
    @property({ attribute: "accessor" }) accessor?: string;
    @property({ attribute: "placeholder" }) placeholder?: string;
    @property({ type: Boolean }) readonly = false;
    @property({ type: Boolean }) isLoading = false;
    //@property({ type: Object }) newRowAsync?: () => Promise<{}>;

    @state() private _editorCol: DataGridColumn;
    @state() private _editorRow: any;
    private _editorCellWidth: string;
    private _editorSaving = false;

    private _isBreakWords = false;
    private _wordsBrokenAtWidth = 0;
    @state() private _sortColumn;
    @state() private _sortOrder;
    @property() defaultSorted?: string = null;

    private _menuService: MenuService;

    @query("#table") private _table: HTMLTableElement;
    @query("#selectAll") private _selectAllcheckb: CheckboxEditorElement;
    @query("#tableContainer") private _tableContainer: HTMLDivElement;
    @queryAll(".checkbox") private _selectedCheckboxes: CheckboxEditorElement[];
    @query("#editor") private _editorElement: SimpleEditor;

    constructor() {
        super();
        this._menuService = container.resolve(MenuService);
    }

    willUpdate(changedProperties) {
        if (changedProperties.has("rows")) {
            this._isBreakWords = false;
            this._wordsBrokenAtWidth = 0;
            if (!this.hasPreSelected) {
                this.clearSelection();
                if (this.selectAllCheckbox && this._selectAllcheckb) this._selectAllcheckb.liveValue = false;
            } else {
                this.hasPreSelected = false;
            }
            //if (this.rows) this.isLoading = false;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        addEventListener("resize", () => this.refreshSize());
        if (this.defaultSorted) {
            this._sortColumn = this.defaultSorted;
            this._sortOrder = SortOrder.Ascending;
        }
    }
    disconnectedCallback() {
        removeEventListener("resize", () => this.refreshSize());
        super.disconnectedCallback();
    }

    /*showLoading(show = true) {
        this.isLoading = show;
    }*/

    showActivated(on: boolean) {
        if (on) this._tableContainer.style.outline = "2px solid #5248F9";
        else this._tableContainer.style.outline = "none";
    }

    private onSelectRow(row: any) {
        row.selected = !row.selected;
        this.dispatchEvent(new CustomEvent("selectionchanged", { bubbles: true, composed: true, detail: { row: row } }));
    }
    selectAllRows() {
        if (!this.rows) return;
        this.rows.forEach((row) => {
            row.selected = true;
        });
        this._selectedCheckboxes.forEach((checkbox) => {
            checkbox.value = true;
        });
    }
    clearSelection() {
        if (!this.rows) return;
        this.rows.forEach((row) => {
            if (row?.selected) row.selected = false;
        });
        this._selectedCheckboxes.forEach((checkbox) => {
            if (checkbox?.value) checkbox.value = false;
            if (checkbox?.liveValue) checkbox.liveValue = false;
        });
    }
    get selectedRows() {
        if (!this.rows) return [];
        return this.rows.filter((p) => p.selected);
    }

    private setWordBreak() {
        if (this._tableContainer) {
            const wasBreakWords = this._isBreakWords;
            if (this._tableContainer.scrollWidth > this._tableContainer.clientWidth + 5) {
                if (!this._isBreakWords) {
                    for (let i = 1; i < this._table.rows.length; i++) {
                        for (let j = 0; j < this._table.rows[i].cells.length; j++) {
                            this._table.rows[i].cells[j].style.wordBreak = "break-all";
                            this._isBreakWords = true;
                            this._wordsBrokenAtWidth = this._tableContainer.clientWidth;
                        }
                    }
                } else {
                    this._tableContainer.style.overflowX = "auto";
                }
            } else if (this._isBreakWords && this._tableContainer.clientWidth > this._wordsBrokenAtWidth + 20) {
                for (let i = 1; i < this._table.rows.length; i++) {
                    for (let j = 0; j < this._table.rows[i].cells.length; j++) {
                        this._table.rows[i].cells[j].style.wordBreak = "normal";
                        this._isBreakWords = false;
                        this._tableContainer.style.overflowX = "hidden";
                    }
                }
            }
            if (wasBreakWords !== this._isBreakWords) {
                setTimeout(() => {
                    this.setWordBreak();
                }, 0);
            }
        }
    }

    private refreshSize() {
        if (this._tableContainer) {
            this._tableContainer.style.overflowX = "hidden";
            setTimeout(() => this.setWordBreak(), 0);
        }
    }

    calculateHeight() {
        setTimeout(() => this.setWordBreak(), 0);
    }

    collapse() {
        this._tableContainer.style.height = "0px";
    }

    private getHeaderValue(col: DataGridColumn) {
        const words = col.title?.split("|") ?? [];
        return html`
            ${col.breakAtPipe && words.length > 1
                ? this._isBreakWords
                    ? html`${words[0]}<img style="width:0px;height:0px" />${words[1]}`
                    : html`${words.join()}`
                : html`${col.title ?? ""}`}
        `;
    }

    private getColumnHeader(col: DataGridColumn) {
        if (col.sortable) {
            const sortIcon =
                this._sortColumn === col.field ? (this._sortOrder === SortOrder.Ascending ? "fa-sort-down" : "fa-sort-up") : "fa-sort";
            return html`
                <span @click=${(evt) => this.sort(evt, col)} class="pointer">
                    <span style="display: flex; gap:5px"
                        ><span>${this.getHeaderValue(col)}</span><fa-icon secondary-color="DarkGray" fa-class="fad ${sortIcon}"></fa-icon
                    ></span>
                </span>
            `;
        } else {
            return this.getHeaderValue(col);
        }
    }

    private sort(evt: Event, col: DataGridColumn) {
        evt.stopPropagation();
        if (!col.sortable || !col.field) {
            return;
        }

        this._sortOrder =
            this._sortColumn !== col.field
                ? SortOrder.Ascending
                : this._sortOrder === SortOrder.Ascending
                  ? SortOrder.Descending
                  : SortOrder.Ascending;
        this._sortColumn = col.field;

        this.dispatchEvent(
            new CustomEvent("sortdata", {
                bubbles: true,
                composed: true,
                detail: { sortColumn: this._sortColumn, sortOrder: this._sortOrder },
            })
        );
    }

    firstUpdated() {
        this._tableContainer.style.overflowX = "hidden";
        setTimeout(() => {
            this.setWordBreak();
        }, 0);
    }

    private getIcon(row: any, col: DataGridColumn) {
        return this.getIconByName(typeof col.icon === "string" ? col.icon : col.icon.call(this, row, col), col.iconColor);
    }

    private getIconByName(icon?: string, iconColor?: string) {
        return html`<fa-icon single-color=${iconColor} fa-class="${icon}"></fa-icon>`;
    }

    private getLink(row: any, col: DataGridColumn) {
        if (col.template) {
            return html`<a href=${col.link.call(row, col)}>${col.template.call(this, row, col)}</a>`;
        } else if (!col.field) {
            return html`<a href=${col.link.call(row, col)}>${this.getIcon(row, col)}</a>`;
        } else {
            if (col.icon) {
                return html`<a href=${col.link.call(row, col)} style="display: flex; gap: 5px"
                    >${this.getIcon(row, col)}<span>${this.getValue(row, col)}</span></a
                >`;
            } else {
                return html`<a href=${col.link.call(row, col)}>${this.getValue(row, col)}</a>`;
            }
        }
    }
    private getAction(row: any, col: DataGridColumn) {
        if (col.template) {
            return html`<span style="cursor: pointer" @click=${() => col.action(row, col)}>${col.template.call(this, row, col)}</span>`;
        } else if (!col.field) {
            return html`<span style="cursor: pointer" @click=${() => col.action(row, col)}>${this.getIcon(row, col)}</span>`;
        } else {
            if (col.icon) {
                return html`<span style="cursor: pointer" @click=${() => col.action(row, col)} style="display: flex; gap: 5px"
                    >${this.getIcon(row, col)}<span>${this.getValue(row, col)}</span></span
                >`;
            } else {
                return html`<span style="cursor: pointer" @click=${() => col.action(row, col)}>${this.getValue(row, col)}</span>`;
            }
        }
    }
    private getActionLink(row: any, col: DataGridColumn) {
        if (col.template) {
            return html`<a href="javascript:;" @click=${() => col.actionLink(row, col)}>${col.template.call(this, row, col)}</a>`;
        } else if (!col.field) {
            return html`<a href="javascript:;" @click=${() => col.actionLink(row, col)}>${this.getIcon(row, col)}</a>`;
        } else {
            if (col.icon) {
                return html`<a href="javascript:;" @click=${() => col.actionLink(row, col)} style="display: flex; gap: 5px"
                    >${this.getIcon(row, col)}<span>${this.getValue(row, col)}</span></a
                >`;
            } else {
                return html`<a href="javascript:;" @click=${() => col.actionLink(row, col)}>${this.getValue(row, col)}</a>`;
            }
        }
    }

    private getBaseValue(row: any, col: DataGridColumn) {
        if (col.getValue) {
            return col.getValue(row, col);
        } else {
            return this.accessor ? row[col.field]?.[this.accessor] ?? undefined : row[col.field];
        }
    }

    private getValue(row: any, col: DataGridColumn) {
        let baseValue = this.getBaseValue(row, col);
        if (col.editorEmptyValue && baseValue === col.editorEmptyValue) {
            baseValue = "";
        }
        if (col.preformatted) {
            return html`<pre style="margin:0; font: var(--font);">${baseValue}</pre>`;
        } else if (col.options) {
            return html`${col.options.find((p) => p.id === (baseValue ?? ""))?.name ?? ""}`;
        } else {
            return html`${baseValue}`;
        }
    }
    private getText(row: any, col: DataGridColumn) {
        let baseValue = this.getBaseValue(row, col);
        if (col.editorEmptyValue && baseValue === col.editorEmptyValue) {
            baseValue = "";
        }
        if (col.icon) {
            return html`<div style="display: flex; gap: 5px">${baseValue}<span></span></div>`;
        } else if (col.checkbox) {
            if (baseValue) return html`<fa-icon style="font: var(--font-input-large)" fa-class="far fa-check"></fa-icon>`;
            else return html``;
        } else {
            return this.getValue(row, col);
        }
    }
    private getButton(row: any, col: DataGridColumn) {
        return html`<button
            class="row-button"            
            @mousedown="${() => col.button(row, col)}"
        >
            ${this.getButtonIcon(row, col)}
        </button>`;
    }
    private getButtonIcon(row: any, col: DataGridColumn) {
        if (col.icon) {
            return this.getIcon(row, col);
        } else {
            return this.getBaseValue(row, col);
        }
    }
    private getDeleteButton(row: any, col: DataGridColumn) {
        const readonly = this.readonly || col.readonly?.(row, col);
        return html`<button
            .disabled=${readonly}                        
            class="${classMap({ "row-button": !readonly, red: !readonly, "row-button-disabled": readonly })}"
            @mousedown="${() => this.deleteRow(row, col)}"
        >
            ${this.getDeleteIcon(row, col)}
        </button>`;
    }
    private getDeleteIcon(row: any, col: DataGridColumn) {
        if (col.icon) {
            return this.getIcon(row, col);
        } else {
            return this.getIconByName("far fa-trash-alt");
        }
    }
    private getMenu(row: any, col: DataGridColumn) {
        if (col.field)
            return html`<div class="menu">
                ${this.getMenuText(row, col)}<button class="row-button" @mousedown="${(evt) => this.openMenu(evt, row, col)}" ${col.htmlTitle ? htmlTitle(this.getHtmlTitle(row, col)) : undefined}>
                    ${this.getMenuIcon(row, col)}
                </button>
            </div>`;
        else
            return html`<button class="row-button" @mousedown="${(evt) => this.openMenu(evt, row, col)}" ${col.htmlTitle ? htmlTitle(this.getHtmlTitle(row, col)) : undefined}>
                ${this.getMenuIcon(row, col)}
            </button>`;
    }
    private getMenuIcon(row: any, col: DataGridColumn) {
        if (col.icon) {
            return this.getIcon(row, col);
        } else {
            return this.getIconByName("far fa-ellipsis-v");
        }
    }
    private getMenuText(row: any, col: DataGridColumn) {
        if (col.field) {
            return html`<span>${this.getBaseValue(row, col)}</span>`;
        } else {
            return html``;
        }
    }

    private getEditorType(row: any, col: DataGridColumn) {
        return typeof col.editor === "string" || !col.editor ? col.editor : col.editor.call(this, row, col);
    }

    private getHtmlTitle(row: any, col: DataGridColumn) {
        return typeof col.htmlTitle === "string" || !col.htmlTitle ? col.htmlTitle : col.htmlTitle.call(this, row, col);
    }

    private onEdit(event: Event, row: any, col: DataGridColumn) {
        event.stopPropagation();
        this._editorCol = col;
        this._editorRow = row;
        const button = event.target as HTMLElement;
        this._editorCellWidth = button.parentElement.offsetWidth + "px";
    }

    private getCellContentWithEdit(row: any, col: DataGridColumn) {
        const editorType = col.editor && this.getEditorType(row, col);
        const content = this.getCellContent(row, col);
        const title = col.htmlTitle && !col.menu ? this.getHtmlTitle(row, col) : (col.isDelete ? "Delete" : undefined);
        const titleStyle =
            title && !col.action && !col.actionLink && !col.button
                ? { borderBottom: "1px dotted", cursor: "pointer", userSelect: "none" }
                : undefined;
        const style =
            !col.align || col.align === "left"
                ? titleStyle
                    ? titleStyle
                    : undefined
                : {
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      alignItems: col.align === "center" ? "center" : "end",
                      ...titleStyle,
                  };
        if (editorType && editorType !== "none" && editorType !== "checkbox") {
            return html`
                <div style="position:relative;">
                    <div class="edit-div">
                        ${style
                            ? html`<span style="${styleMap(style)}" ${title ? htmlTitle(title, true) : undefined}>${content}</span>`
                            : content}
                        ${this.readonly || col.readonly?.(row, col)
                            ? html``
                            : html`<button
                                  ?disabled=${this._editorSaving}
                                  class="button edit-icon"
                                  @mousedown=${(event) => this.onEdit(event, row, col)}
                                  ${htmlTitle("Edit " + col.title)}
                              >
                                  ${this.getIconByName("far fa-pencil")}
                              </button>`}
                    </div>
                </div>
            `;
        } else {
            return style ? html`<span style="${styleMap(style)}" ${title ? htmlTitle(title, true) : undefined}>${content}</span>` : content;
        }
    }

    private getCellContent(row: any, col: DataGridColumn) {
        if (col.component) {
            return html`${this.getComponent(col.component, col, row)}`;
        } else if (col.template && !col.actionLink && !col.link && !col.action) {
            return col.template.call(this, row, col);
        } else if (col.menu) {
            return this.getMenu(row, col);
        } else if (col.isDelete) {
            return this.getDeleteButton(row, col);
        } else if (col.button) {
            return this.getButton(row, col);
        } else {
            if (this.getEditorType(row, col) === "checkbox") return this.getEditCheckBox(row, col);
            else if (col.link) return this.getLink(row, col);
            else if (col.action) return this.getAction(row, col);
            else if (col.actionLink) return this.getActionLink(row, col);
            else if (!col.field) {
                if (col.icon) return this.getIcon(row, col);
                else return "";
            } else return this.getText(row, col);
        }
    }

    private getEditCheckBox(row: any, col: DataGridColumn) {
        const readonly = this.readonly || col.readonly?.(row, col);
        if (readonly && col.checkbox) {
            return this.getText(row, col);
        } else {
            return html`<se-checkbox-inline-editor
                .disabled=${this._editorSaving || readonly}
                @input=${(evt) => evt.target.setCustomValidity("")}
                @valueChanged=${(evt) => this.inputChanging(evt, row, col)}
                .value=${this.getBaseValue(row, col)}
            ></se-checkbox-inline-editor>`;
        }
    }

    private getComponent(template: string, col: DataGridColumn, row: any) {
        const temp = document.createElement(template);
        if (temp instanceof DataGridTemplate) {
            temp.column = col;
            temp.row = row;
        } else {
            throw new Error("Unexpected template type.");
        }
        return temp;
    }

    private openMenu(event: MouseEvent, row: any, col: DataGridColumn) {
        event.stopPropagation();
        this._menuService.openContextMenu({
            button: event.target as HTMLElement,
            activeClass: "menu-active",
            menu: col.menu.call(this, row, col),
        });
    }

    updated() {
        setTimeout(() => {
            this.setWordBreak();
        }, 0);
    }

    private inputChanging(event: Event, row: any, col: DataGridColumn) {
        event.stopPropagation();
        const input = event.target as unknown as SimpleEditor;
        if (col.setValue) {
            this.saveEditor(input, row, col);
        } else {
            this.save(input, row, col);
        }
    }

    private getCellPadding() {
        return (
            {
                padding:
                    this.layout === "compact"
                        ? this.saveMode === "all"
                            ? "2px 5px"
                            : "5px 5px"
                        : this.saveMode === "all"
                          ? "5px 5px"
                          : "10px 5px",
            } ?? {}
        );
    }

    private getSaveAllEditorCell(row: any, col: DataGridColumn) {
        if (this.getEditorType(row, col) !== "none") {
            const align =
                col.align === "center" ? { textAlign: "center" } : col.align === "right" ? { textAlign: "right" } : { textAlign: "left" };
            return html` <td
                style="${styleMap({ ...col.cellStyle, ...this.getCellPadding(), ...align })}"
                class="${classMap(col.cellClass ?? {})}"
            >
                ${this.getEditor(row, col)}
            </td>`;
        } else {
            return html``;
        }
    }

    saveGrid() {
        if (this._editorElement) {
            if (this._editorCol.setValue) {
                this.saveEditor(this._editorElement, this._editorRow, this._editorCol);
            } else {
                this.save(this._editorElement, this._editorRow, this._editorCol);
            }
        }
    }

    private onEditCancel(event: Event) {
        event.stopPropagation();
        this._editorCellWidth = undefined;
        this._editorCol = undefined;
        this._editorRow = undefined;
    }
    private onEditSave(event: Event, row: any, col: DataGridColumn) {
        event.stopPropagation();
        const button = event.target as HTMLElement;
        const input = button.parentElement.firstElementChild as unknown as SimpleEditor;
        if (col.setValue) {
            this.saveEditor(input, row, col);
        } else {
            this.save(input, row, col);
        }
    }
    private async saveEditor(input: SimpleEditor, row: any, col: DataGridColumn) {
        const result = col.setValue(input.liveValue, row, col);
        if (result instanceof Promise) {
            this._editorSaving = true;
            const nextResult = await result;
            this._editorSaving = false;
            if (nextResult.success) {
                if (this.accessor) row[col.field][this.accessor] = input.liveValue;
                else row[col.field] = input.liveValue;
                this._editorCellWidth = undefined;
                this._editorCol = undefined;
                this._editorRow = undefined;
            } else {
                input.setCustomValidity(nextResult.errorMessage ?? "Error saving value.");
            }
            await input.reportValidity();
        } else if (result.success) {
            if (this.accessor) row[col.field][this.accessor] = input.liveValue;
            else row[col.field] = input.liveValue;
            this._editorCellWidth = undefined;
            this._editorCol = undefined;
            this._editorRow = undefined;
            await input.reportValidity();
        } else {
            input.setCustomValidity(result.errorMessage ?? "Error saving value.");
            await input.reportValidity();
        }
    }

    private save(input: SimpleEditor, row: any, col: DataGridColumn) {
        input.setCustomValidity("");
        if (this.accessor) row[col.field][this.accessor] = input.liveValue;
        else row[col.field] = input.liveValue;
        this._editorCellWidth = undefined;
        this._editorCol = undefined;
        this._editorRow = undefined;
    }

    private async deleteRow(row: any, col: DataGridColumn) {
        if (!this.rows) return;
        if (col.deleteRow) {
            const result = col.deleteRow(row, col);
            if (result instanceof Promise) {
                this._editorSaving = true;
                if (await result) {
                    const index = this.rows.indexOf(row);
                    this.rows.splice(index, 1);
                    this.requestUpdate();
                }
                this._editorSaving = false;
            } else if (result) {
                const index = this.rows.indexOf(row);
                this.rows.splice(index, 1);
                this.requestUpdate();
            }
        } else {
            const index = this.rows.indexOf(row);
            this.rows.splice(index, 1);
            this.requestUpdate();
        }
    }

    private getEditor(row: any, col: DataGridColumn) {
        const readonly = this.readonly || col.readonly?.(row, col);
        const editorType = col.editor && this.getEditorType(row, col);
        if (editorType === "text" || editorType === "password") {
            const style = { flex: "1", width: "100%", minWidth: this.layout === "compact" ? "100px" : "150px" };
            return html`<se-input-inline-editor
                input-width=${col.editorWidth ?? "100%"}
                type=${editorType === "password" ? "password" : "text"}
                id=${this.saveMode === "cell" ? "editor" : undefined}
                .disabled=${this._editorSaving || readonly}
                style=${styleMap(style)}
                @input=${(evt) => evt.target.setCustomValidity("")}
                value=${this.getBaseValue(row, col) ?? ""}
                @valueChanged=${this.saveMode === "all" ? (evt) => this.inputChanging(evt, row, col) : undefined}
                @editorKeyEnter=${this.saveMode === "all" ? undefined : () => this.saveGrid()}
            ></se-input-inline-editor>`;
        } else if (editorType === "text-area") {
            const style = { flex: "1", width: "100%", minWidth: this.layout === "compact" ? "100px" : "150px" };
            const lines = this.getBaseValue(row, col)?.split("\n").length ?? 2;
            return html`<se-textarea-editor
                input-width=${col.editorWidth ?? "100%"}
                id=${this.saveMode === "cell" ? "editor" : undefined}
                .disabled=${this._editorSaving || readonly}
                style=${styleMap(style)}
                @input=${(evt) => evt.target.setCustomValidity("")}
                .value=${this.getBaseValue(row, col) ?? ""}
                rows=${lines < 2 ? 2 : lines}
                @valueChanged=${this.saveMode === "all" ? (evt) => this.inputChanging(evt, row, col) : undefined}
            ></se-textarea-editor>`;
        } else if (editorType === "select") {
            const style = { flex: "1", width: "100%", minWidth: this.layout === "compact" ? "100px" : "150px" };
            return html`<se-select-inline-editor
                input-width=${col.editorWidth ?? "100%"}
                id=${this.saveMode === "cell" ? "editor" : undefined}
                .disabled=${this._editorSaving || readonly}
                style=${styleMap(style)}
                @input=${(evt) => evt.target.setCustomValidity("")}
                @valueChanged=${this.saveMode === "all" ? (evt) => this.inputChanging(evt, row, col) : undefined}
                .options=${col.options}
                .value=${this.getBaseValue(row, col)}
                empty-value=${col.editorEmptyValue}
            ></se-select-inline-editor>`;
        } else if (editorType === "checkbox") {
            const style = { flex: "1" };
            if (readonly && col.checkbox) {
                return this.getText(row, col);
            } else {
                return html`<se-checkbox-inline-editor
                    id=${this.saveMode === "cell" ? "editor" : undefined}
                    .disabled=${this._editorSaving || readonly}
                    style=${styleMap(style)}
                    @input=${(evt) => evt.target.setCustomValidity("")}
                    @valueChanged=${this.saveMode === "all" ? (evt) => this.inputChanging(evt, row, col) : undefined}
                    .value=${this.getBaseValue(row, col)}
                ></se-checkbox-inline-editor>`;
            }
        } else if (editorType === "number") {
            const style = { flex: "1", width: "100%", minWidth: this.layout === "compact" ? "50px" : "75px" };
            return html`<se-number-inline-editor
                input-width=${col.editorWidth ?? "100%"}
                id=${this.saveMode === "cell" ? "editor" : undefined}
                .disabled=${this._editorSaving || readonly}
                style=${styleMap(style)}
                @input=${(evt) => evt.target.setCustomValidity("")}
                @valueChanged=${this.saveMode === "all" ? (evt) => this.inputChanging(evt, row, col) : undefined}
                .value=${this.getBaseValue(row, col)}
            ></se-number-inline-editor>`;
        } else if (editorType === "typeahead") {
            const style = { flex: "1", width: "100%", minWidth: this.layout === "compact" ? "100px" : "150px" };
            return html`<se-typeahead-inline-editor
                input-width=${col.editorWidth ?? "100%"}
                id=${this.saveMode === "cell" ? "editor" : undefined}
                .disabled=${this._editorSaving || readonly}
                style=${styleMap(style)}
                @input=${(evt) => evt.target.setCustomValidity("")}
                @valueChanged=${this.saveMode === "all" ? (evt) => this.inputChanging(evt, row, col) : undefined}
                .textOptions=${col.textOptions}
                show-all
                .value=${this.getBaseValue(row, col)}
            ></se-typeahead-inline-editor>`;
        }
    }

    private getSaveCellEditorCell(row: any, col: DataGridColumn) {
        if (this.getEditorType(row, col) !== "none") {
            const readonly = this.readonly || col.readonly?.(row, col);
            return html` <td
                style="${styleMap({ ...col.cellStyle, ...this.getCellPadding(), width: this._editorCellWidth } ?? {})}"
                class="${classMap(col.cellClass ?? {})}"
            >
                <div class="cell-editor-div">
                    ${this.getEditor(row, col)}
                    <button
                        class="button"
                        ?disabled=${this._editorSaving || readonly}
                        @click=${(event) => this.onEditSave(event, row, col)}
                    >
                        ${this.getIconByName("far fa-check")}
                    </button>
                    <button class="button" ?disabled=${this._editorSaving || readonly} @click=${(evt) => this.onEditCancel(evt)}>
                        ${this.getIconByName("far fa-times")}
                    </button>
                </div>
            </td>`;
        } else {
            return html``;
        }
    }
    cancel() {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            (elem as unknown as BaseEditor)?.cancel?.();
        }
        this.requestUpdate();
    }
    private async addNewRow(evt: Event) {
        evt.stopPropagation();
        if (!this.rows) return;

        this._editorSaving = true;
        if (this.newRow) {
            const result = this.newRow();
            if (result instanceof Promise) {
                const row = await result;
                if (row) this.rows.push(row);
            } else {
                this.rows.push(result);
            }
        } else {
            this.rows.push({});
        }
        this._editorSaving = false;
        this.requestUpdate();
    }
    private selectAllCheck(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this.selectAllRows();
            } else {
                this.clearSelection();
            }
        }
    }
    render() {
        let isOdd = true;
        const tableClass = { compact: this.layout === "compact" };
        return html`
            <div id="tableContainer" class="tableContainer" style=${styleMap({ height: this.fullHeight ? "100%" : undefined })}>
                <table id="table" class="table ${classMap(tableClass)}" style="${styleMap(this.styles ?? {})}">
                    <tr>
                        ${when(
                            this.selectable,
                            () =>
                                html`<th style="width:20px">
                                    ${when(
                                        this.selectAllCheckbox,
                                        () =>
                                            html`<se-checkbox-editor
                                                style="margin-right:5px"
                                                id="selectAll"
                                                tristate="auto"
                                                @valueChanged=${this.selectAllCheck}
                                                .value=${false}
                                                .disabled=${!this.rows || this.rows.length === 0}
                                            ></se-checkbox-editor>`
                                    )}
                                </th>`
                        )}
                        ${this.columns
                            .filter((p) => !p.hidden)
                            .map((col) => {
                                if (col.align && col.align !== "left") {
                                    const style = {
                                        display: "flex",
                                        justifyContent: col.align === "center" ? "center" : "right",
                                        textAlign: col.align === "center" ? "center" : "right",
                                    };
                                    return html`<th style="${styleMap(col.headerStyle ?? {})}">
                                        <div style=${styleMap(style)}>${this.getColumnHeader(col)}</div>
                                    </th>`;
                                } else {
                                    return html`<th style="${styleMap(col.headerStyle ?? {})}">${this.getColumnHeader(col)}</th>`;
                                }
                            })}
                    </tr>
                    ${when(
                        this.newButton === "top",
                        () =>
                            html`<tr>
                                <td colspan=${this.columns.length + (this.selectable ? 1 : 0)}>
                                    <button ?disabled=${this._editorSaving || this.readonly} class="button" @click=${this.addNewRow}>
                                        New
                                    </button>
                                </td>
                            </tr>`
                    )}
                    ${this.isLoading
                        ? html`<tr>
                              <td
                                  style="text-align: center; color: gray; font-size:0.95em;padding:10px"
                                  colspan=${this.columns.length + (this.selectable ? 1 : 0)}
                              >
                                  <fa-icon
                        style="font-size:0.9em"
                        fa-class="far fa-spinner fa-spin">
                    </fa-icon>&nbsp;&nbsp;<span>Loading...</<span>
                              </td>
                          </tr>`
                        : html` ${this.rows &&
                          this.rows.map((row) => {
                              isOdd = !isOdd;
                              return html`<tr class="${classMap({ alt: isOdd })}">
                                  ${when(
                                      this.selectable,
                                      () => html`
                                          <td style=${styleMap(this.getCellPadding())}>
                                              <se-checkbox-editor
                                                  tristate="never"
                                                  class="checkbox"
                                                  @valueChanged="${() => this.onSelectRow(row)}"
                                                  .value="${row.selected}"
                                              ></se-checkbox-editor>
                                          </td>
                                      `
                                  )}
                                  ${this.columns
                                      .filter((p) => !p.hidden)
                                      .map((col) => {
                                          if (col.editor && col.editor !== "none" && this.saveMode === "all") {
                                              return this.getSaveAllEditorCell(row, col);
                                          } else if (
                                              col.editor &&
                                              col.editor !== "none" &&
                                              this._editorCol === col &&
                                              this._editorRow === row
                                          ) {
                                              return this.getSaveCellEditorCell(row, col);
                                          } else if (col.align && col.align !== "left") {
                                              /*const style = {
                                        display: "flex",
                                        justifyContent: col.align === "center" ? "center" : "right",
                                        textAlign: col.align === "center" ? "center" : "right",
                                    };*/
                                              return html` <td
                                                  style="${styleMap({ ...col.cellStyle, ...this.getCellPadding() })}"
                                                  class="${classMap(col.cellClass ?? {})}"
                                              >
                                                  ${this.getCellContentWithEdit(row, col)}
                                              </td>`;
                                          } else {
                                              return html` <td
                                                  style="${styleMap({ ...col.cellStyle, ...this.getCellPadding() })}"
                                                  class="${classMap(col.cellClass ?? {})}"
                                              >
                                                  ${this.getCellContentWithEdit(row, col)}
                                              </td>`;
                                          }
                                      })}
                              </tr>`;
                          })}
                          ${(!this.rows || this.rows.length === 0) && this.placeholder
                              ? html`<tr>
                                    <td
                                        style="text-align: center; color: gray; font-size:0.75em;padding:10px"
                                        colspan=${this.columns.length + (this.selectable ? 1 : 0)}
                                    >
                                        ${this.placeholder}
                                    </td>
                                </tr>`
                              : html``}
                          ${when(
                              this.newButton === "bottom",
                              () =>
                                  html`<tr>
                                      <td colspan=${this.columns.length + (this.selectable ? 1 : 0)}>
                                          <button ?disabled=${this._editorSaving || this.readonly} class="button" @click=${this.addNewRow}>
                                              New
                                          </button>
                                      </td>
                                  </tr>`
                          )}`}
                </table>
            </div>
        `;
    }

    static styles = css`
        :host {
            font: var(--font);
            min-height: 0px;
            min-width: 0px;
        }
        .cell-editor-div {
            display: inline-flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 5px;
            width: 100%;
        }
        .input {
            font: var(--font);
            width: 100%;
            border: 1px solid lightgray;
        }
        input:invalid {
            border: 1px solid salmon;
        }

        .edit-icon {
            opacity: 0;
            position: absolute;
            right: 0px;
        }
        div.edit-div {
            display: flex;
            flex-direction: column;
            min-height: 1.4em;
        }
        td:hover .edit-icon {
            opacity: 1;
        }

        .table td {
            padding: 10px 5px;
            vertical-align: top;
            overflow-wrap: break-word;
            word-wrap: break-word;
            color: rgba(0, 0, 0, 0.87);
        }

        .table.compact td {
            padding: 5px 5px;
        }

        tr.alt {
            background: #fcfcfc;
        }

        .table th {
            padding: 10px 5px;
            vertical-align: top;
            overflow-wrap: break-word;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            background: #f2f2f2;
            position: sticky;
            top: 0;
            z-index: 2;
            border-top: 0px;
            text-align: left;
        }

        .table.compact th {
            padding: 5px 5px;
            font-weight: normal;
        }

        .table {
            width: 100%;
            table-layout: auto;
            border-collapse: collapse;
        }

        .tableContainer {
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            border: 1px solid darkgray;
            box-shadow: 2px 2px 2px lightGray;
            background-color: white;
            border-radius: 5px 5px 5px 5px;
        }
        .pointer {
            cursor: pointer;
        }
        .menu {
            display: flex;
            gap: 5px;
        }
        .row-button-disabled {
            border: 1px solid lightgray;
            border-radius: 3px 3px;
            background-color: whitesmoke;
            padding: 2px 4px;
        }
        .row-button {
            border: 1px solid lightgray;
            border-radius: 3px 3px;
            background-color: whitesmoke;
            transition: all 0.25s;
            padding: 2px 4px;
            user-select: none;
        }
        .row-button:hover {
            box-shadow: 2px 2px 2px gray;
            border: 1px solid gray;
            border-radius: 3px 3px;
            background-color: white;
        }
        .row-button.red:hover {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .row-button:active {
            background-color: white;
            box-shadow: none !important;
            border: solid 1px gray;
        }
        .row-button.red:active {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
            box-shadow: none !important;
        }
        .menu-active {
            background-color: white;
            box-shadow: none !important;
            border: solid 1px gray;
        }

        .button {
            border: 1px solid grey;
            border-radius: 3px 3px;
            background-color: white;
            transition:
                all 0.25s,
                opacity 0.5s;
            padding: 2px 4px;
            margin: 0;
            user-select: none;
        }
        .button:disabled {
            background-color: white;
            border: 1px solid lightgray;
            color: gray;
            transition: none;
        }
        .button:hover {
            box-shadow: 2px 2px 2px lightgray;
            border: 1px solid black;
        }
        .button.red:hover {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .button:hover:disabled {
            border: 1px solid lightgray;
            box-shadow: none;
        }
        .button:active {
            box-shadow: none;
        }
        .button.red:active {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }

        .distribution {
            display: flex;
            min-width: 100px;
            height: 16px;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: var(--color-blue-50, #cee1f4);
            overflow: hidden;
            flex-shrink: 1;
        }

        .distribution span {
            border-radius: 8px;
            background-color: var(--color-status-blue);
        }
    `;
}
