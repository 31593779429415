import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { container } from "tsyringe";
import { NetworkResult } from "se-shared/utils/result";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigType } from "../../enums/config-type";
import { isReadyToRun, isRunningOnServerOrQueingOrWaiting, RunStatus } from "../../enums/run-status";
import { ValidationStatus } from "../../enums/validation-status";
import { ConfigSummaryViewModel } from "../../models/config-summary-view-model";
import { ConfigsSummaryViewModel } from "../../models/configs-summary-view-model";
import { DraftSummaryViewModel } from "../../models/draft-summary-view-model";
import { AuthService } from "../../services/auth.service";
import { ConfigApi } from "../../services/config.api";
import { ConfigService } from "../../services/config.service";
import { DraftService } from "../../services/draft.service";
import { FileUploadApi } from "../../services/file-upload.api";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { MenuItem } from "../components/context-menu.element";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/data-grid.element";
import { SeDataGrid } from "../components/data-grid.element";
import "../components/dropdown-button.element";
import { SeDropDownButton } from "../components/dropdown-button.element";
import "../components/pagination.element";
import { SePaginationElement } from "../components/pagination.element";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "../menu-editors/apply-templates.element";
import "../menu-editors/assign-shared-files.element";
import "../menu-editors/copy-configs.element";
import "../menu-editors/move-configs.element";
import "./name-column";
import "./space-search-editor.element";
import { RunApi } from "../../services/run.api";
Chart.register(...registerables);
import { Chart, ChartType, registerables } from "chart.js";

@customElement("se-space")
export class SeSpaceElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _draftService: DraftService;
    private _configService: ConfigService;
    private _configApi: ConfigApi;
    private _runApi: RunApi;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _serverService: ServerService;
    private _fileUploadApi: FileUploadApi;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn = "name";

    private _sortOrder = -1;
    private _fileterType: "none" | "label" | "template" | "shared-file" = "none";
    private _fileterId?: number;
    @state() private _data: (ConfigSummaryViewModel | DraftSummaryViewModel)[];

    @state() private _hasSelectedRows = false;
    @state() private _hasSelectedAgentsWithUnknownValidation = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("se-pagination") private _dataPagination: SePaginationElement;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#newAgentButton") private _newAgentElement: SeDropDownButton;

    private loadDataTimeout: NodeJS.Timeout;

    @query("#doughnutChartCanvas") private _doughnutChartCanvas: HTMLCanvasElement;
    @query("#trendsChartCanvas") private _trendsChartCanvas: HTMLCanvasElement;
    private doughnutChart?: Chart<"doughnut", number[], string>;
    private trendsChart?: Chart;

    private _last2WeeksData;
    private _generalRunsStatus: number[];
    private _agentCount = 0;
    @state() private _spaceName = "";
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._serverService = container.resolve(ServerService);
        this._toasterService = container.resolve(ToasterService);
        this._draftService = container.resolve(DraftService);
        this._userState = container.resolve(UserState);
        this._configApi = container.resolve(ConfigApi);
        this._runApi = container.resolve(RunApi);
        this._configService = container.resolve(ConfigService);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);
        this._fileUploadApi = container.resolve(FileUploadApi);
    }

    private draftMenu(row: DraftSummaryViewModel, col: DataGridColumn): MenuItem[] {
        return [
            {
                text: "Open",
                menu: [
                    { text: "Open In Editor", action: this.openDraft.bind(this, row, col) },
                    { text: "Open As Text", action: this.openDraft.bind(this, row, col) },
                ],
            },
            { text: "-" },
            { text: "Publish", hidden: !row.configId, action: this.publishDraftAsync.bind(this, row, col) },
            { text: "Publish To...", action: this.publishDraftToSpaceAsync.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteDraftAsync.bind(this, row, col) },
        ];
    }

    private configMenu(row: ConfigSummaryViewModel, col: DataGridColumn): MenuItem[] {
        return [
            /*{
                text: "Open",
                menu: [
                    { text: "Open In Editor", action: this.openConfig.bind(this, row, col) },
                    { text: "Open As Text", action: this.openConfig.bind(this, row, col) },
                ],
            }
            { text: "-" },*/
            {
                text: "Run",
                hidden: row.configType === ConfigType.SharedFile,
                menu: [
                    { text: "Setup Run", action: this.openRunEditor.bind(this, row) },
                    { text: "-" },
                    { text: "Current Runs", action: this.openActiveRuns.bind(this, row, col) },
                    { text: "Current Tasks", action: this.openActiveTasks.bind(this, row, col) },
                    { text: "-" },
                    { text: "Delete All Runs", action: this.deleteConfigRunsAsync.bind(this, row, col) },
                ],
            },
            { text: "-", hidden: row.configType === ConfigType.SharedFile },
            { text: "Copy Here", action: this.copyAsync.bind(this, row, col) },
            { text: "Copy To Home", hidden: this._userState.selectedSpaceId === 0, action: this.copyToHomeAsync.bind(this, row, col) },
            { text: "Rename", action: this.renameAsync.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteConfigAsync.bind(this, row, col) },
        ];
    }

    private newMenu(): MenuItem[] {
        return [
            {
                text: "New Agent",
                action: () =>
                    this._newAgentElement.doAction(
                        this.openEditor.bind(this, { configType: ConfigType.Agent, spaceId: this._userState.selectedSpaceId })
                    ),
            },
            { text: "-" },
            {
                text: "New Agent Template",
                action: () =>
                    this._newAgentElement.doAction(
                        this.openEditor.bind(this, { configType: ConfigType.Template, spaceId: this._userState.selectedSpaceId })
                    ),
            },
            {
                text: "New Shared File",
                action: () =>
                    this._newAgentElement.doAction(
                        this.newSharedFile.bind(this, { configType: ConfigType.SharedFile, spaceId: this._userState.selectedSpaceId })
                    ),
            },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.gridRowsChanged.bind(this, this.gridRowsChanged);
        this._userState.gridDataChanged.bind(this, this.onGridDataChanges);
        document.addEventListener('leftMenuChanged', () => this.handleLeftMenuChanged());
        this.loadDataAsync();
    }
    disconnectedCallback() {
        this._userState.gridRowsChanged.unbind(this, this.gridRowsChanged);
        this._userState.gridDataChanged.unbind(this, this.onGridDataChanges);
        document.removeEventListener('leftMenuChanged', () => this.handleLeftMenuChanged());
        super.disconnectedCallback();
    }
    private handleLeftMenuChanged() {
        this._sortColumn = "name"; 
    }
    firstUpdated() {
        this.loadDoughnutChart = this.loadDoughnutChart.bind(this);
        window.addEventListener("resize", this.loadDoughnutChart);
    }
    private onGridDataChanges() {
        this._fileterType = this._userState.selectedLabelId > 0 ? "label" : "none";
        this.loadDataAsync();
    }

    private openRunEditor(row: ConfigSummaryViewModel) {
        Router.go(`space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}/config/${row.id}/run`);
    }

    private gridRowsChanged() {
        this._hasSelectedAgentsWithUnknownValidation =
            this._dataGrid.selectedRows.filter(
                (p) => p.configType === ConfigType.Agent && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
            ).length > 0;
        this._dataGrid.requestUpdate();
    }

    private openDraft(row: DraftSummaryViewModel) {
        if (this._userState.selectedSpaceId === -1) {
            this.openEditor({ draftId: row.id });
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async deleteDraftAsync(row: DraftSummaryViewModel) {
        if (this._userState.selectedSpaceId === -1) {
            const result = await this._draftService.api.deleteAsync(row.id);
            if (result.isOk) {
                this._data = this._data.filter((p) => p.id !== row.id);
                this._userState.updateDraftCount(this._data.length);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async publishDraftAsync(row: DraftSummaryViewModel) {
        if (this._userState.isDraftSpace) {
            await this._modalService.openPublishDialogAsync({ data: this._data, row: row });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async publishDraftToSpaceAsync(row: DraftSummaryViewModel) {
        if (this._userState.isDraftSpace) {
            await this._modalService.openPublishToSpaceDialogAsync({
                data: this._data,
                draftId: row.id,
                defaultName: row.name,
                defaultSpace: row.spaceName,
            });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private openConfig(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            this.openEditor({ configId: row.id });
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async deleteConfigRunsAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            const modalResult = await this._modalService.openConfirmDialogAsync({
                title: "Delete All Runs",
                body: `Are you sure you want to delete all ${row.name} runs? This will also reset the run sequence back to zero.`,
                saveCaption: "Delete all",
            });
            if (modalResult.isSave) {
                const result = await this._configService.api.deleteAllRunsAsync(row.id);
                if (result.isOk) {
                    this.loadDataAsync();
                } else {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async deleteConfigAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            const modalResult = await this._modalService.openConfirmDialogAsync({
                title: "Delete Config",
                body: `Are you sure you want to delete ${row.name}?`,
                saveCaption: "Delete",
            });
            if (modalResult.isSave) {
                const result = await this._configService.api.deleteAsync(row.id);
                if (result.isOk) {
                    const index = this._data.indexOf(row);
                    this._data.splice(index, 1);
                    if (row["selected"]) this.gridSelectionChanged();
                    this._dataGrid.requestUpdate();
                } else {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async copyToHomeAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            const result = await this._configService.api.copyToSpaceAsync(row.id, [0]);
            if (!result.isOk) {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async copyAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            await this._modalService.openCopyDialogAsync({ configId: row.id, defaultName: row.name + " Copy" });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async renameAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            await this._modalService.openRenameDialogAsync({ configId: row.id, defaultName: row.name });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private openVersionHistory(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/versions`);
    }

    private openActiveRuns(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/runs`);
    }
    private openDetails(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        if (row.configType === ConfigType.SharedFile) {
            Router.go(`/space/${this._userState.selectedSpaceId}/shared-file/${row.id}/details`);
        } else {
            Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/runs`);
        }
    }
    private openActiveTasks(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/tasks`);
    }

    private setCurrentConfig(row: ConfigSummaryViewModel) {
        this._userState.selectedConfigName = row.name;
        this._userState.selectedConfigId = row.id;
        this._userState.selectedConfigType = row.configType;
        this._userState.selectedConfigValidationStatus = row.validationStatus;
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        if (location.params.templateId) {
            this._fileterType = "template";
            this._fileterId = parseInt(location.params.templateId.valueOf() as string);
        } else if (location.params.sharedFileId) {
            this._fileterType = "shared-file";
            this._fileterId = parseInt(location.params.sharedFileId.valueOf() as string);
        }
    }

    async saveTitleEditorAsync(newValue: string, row?: ConfigSummaryViewModel): Promise<{ success: boolean; errorMessage?: string }> {
        if (newValue === "invalid") {
            return new Promise((resolve) => {
                resolve({ success: false, errorMessage: "Value cannot change." });
            });
        } else {
            const result = await this._configService.api.renameAsync(row.id, newValue);
            if (result.isOk) {
                if (row.configType === ConfigType.Template) {
                    this.refresh();
                }
                return { success: true };
            } else {
                return { success: false, errorMessage: result.err.message };
            }
        }
    }

    private getIconTemplate(validationStatus: ValidationStatus, icon: string) {
        return html`<span
            ${validationStatus !== ValidationStatus.Valid
                ? htmlTitle(validationStatus === ValidationStatus.Invalid ? "Agent is invalid" : "Agent has not been validated")
                : undefined}
            ><fa-icon
                class="icon"
                single-color=${validationStatus === ValidationStatus.Valid
                    ? "gray"
                    : validationStatus === ValidationStatus.Invalid
                      ? "salmon"
                      : "goldenrod"}
                fa-class="far ${icon}"
            ></fa-icon
        ></span>`;
    }

    private async loadDataAsync(isForceRefresh = true) {
        if (this.loadDataTimeout) {
            clearTimeout(this.loadDataTimeout);
            this.loadDataTimeout = undefined;
        }

        if (this._userState.isDraftSpace) {
            this._columns = [
                {
                    field: "configType",
                    cellStyle: { width: "0px", "text-align": "center" },
                    title: "",
                    template: (row) =>
                        html`${choose(
                            row.configType,
                            [
                                [
                                    ConfigType.Agent,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-robot"></fa-icon>`,
                                ],
                                [
                                    ConfigType.Template,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-cubes"></fa-icon>`,
                                ],
                                [
                                    ConfigType.SharedFile,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`,
                                ],
                            ],
                            () => html`${row.configType}`
                        )}`,
                },
                {
                    field: "name",
                    title: "Name",
                    sortable: true,
                    action: this.openDraft.bind(this),
                    template: (row, col) =>
                        html` <a href="javascript:;" @click=${() => col.action(row, col)}
                                ><span
                                    style="${row.name?.length > 50
                                        ? "text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 20vw;display: inline-block;"
                                        : ""}"
                                    title="${row.name}"
                                    >${row.name}</span
                                ></a
                            >${row.isRecovery ? html`&nbsp;&nbsp;(Recovered)` : html``}`,
                },
                { field: "spaceName", title: "Space", sortable: true },
                { field: "configVersion", title: "Version", align: "center" },
                {
                    field: "updated",
                    title: "Updated",
                    sortable: true,
                    align: "center",
                    template: (row) => html`${new Date(row.updated).toLocaleDateString()}`,
                },
                { name: "menu", align: "center", cellStyle: { width: "20px" }, menu: (row, col) => this.draftMenu(row, col) },
            ];

            if (isForceRefresh) this._isLoading = true;
            try {
                const result = await this._draftService.api.getAllAsync(
                    this._pageIndex,
                    this._recordsPerPage,
                    this._sortColumn,
                    this._sortOrder
                );
                if (result.isOk) {
                    this._data = result.value.draftSummaries;
                    this._totalRecordCount = result.value.totalRecordCount;

                    this._userState.updateDraftCount(this._totalRecordCount);
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.resetPagination();
                } else {
                    this._toasterService.showUnexpectedError(result.err.message);
                }
            } finally {
                this._isLoading = false;
            }
        } else if (
            (this._userState.selectedLabelId !== -1 || this._fileterType === "template" || this._fileterType === "shared-file") &&
            (isForceRefresh || (!this._hasSelectedRows && !this._menuService.isMenuOpen()))
        ) {
            //if selectedLabelId is -1 and agentTemplate is -1 then user probably navigating back and this function will be called again when selectedLabelId has been set correctly.
            this._columns = [
                {
                    field: "configType",
                    cellStyle: { width: "0px", "text-align": "center" },
                    title: "",
                    template: (row) =>
                        html`${choose(
                            row.configType,
                            [
                                [ConfigType.Agent, () => this.getIconTemplate(row.validationStatus, "fa-robot")],
                                [ConfigType.Template, () => this.getIconTemplate(row.validationStatus, "fa-cubes")],
                                [
                                    ConfigType.SharedFile,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`,
                                ],
                            ],
                            () => html`${row.configType}`
                        )}`,
                },
                {
                    field: "name",
                    title: "Name",
                    sortable: true,
                    component: "se-name-column",
                    action: (row) => this.openDetails(row),
                    editor: "text",
                    setValue: (newValue: string, row?: ConfigSummaryViewModel) => this.saveTitleEditorAsync(newValue, row)                    
                },
                {
                    field: "version",
                    title: "Version",
                    align: "center",
                    cellStyle: { width: "50px" },
                    actionLink: this.openVersionHistory.bind(this),
                    template: (row) => {
                        return row.configType !== ConfigType.SharedFile ? html`${row.version}` : html``;
                    },
                    htmlTitle: "View all versions"
                },
                {
                    field: "updated",
                    title: "Updated",
                    sortable: true,
                    align: "center",
                    template: (row) => html`${new Date(row.updated).toLocaleDateString()}`,
                },
                {
                    field: "lastActivity",
                    title: "Activity",
                    sortable: true,
                    align: "center",
                    template: (row) => {
                        return row.configType !== ConfigType.SharedFile
                            ? when(row.lastActivity, () => html`${new Date(row.lastActivity).toLocaleDateString()}`)
                            : html``;
                    },
                },
                {
                    field: "status",
                    title: "Status",
                    align: "center",                   
                    sortable: true,
                    template: (row) => {
                        return row.configType !== ConfigType.SharedFile
                            ? html`<a ${htmlTitle("View run details")} href="javascript:;" @click=${() => this.openActiveRuns(row)}
                                  >${choose(
                                      row.status,
                                      [
                                          [
                                              undefined || null,
                                              () =>
                                                  html`<span style="color:var(--color-gray-4);text-decoration: underline;"
                                                      >No Activity</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.success,
                                              () =>
                                                  html`<span style="color:var(--color-status-green);text-decoration: underline;"
                                                      >Success</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.failure,
                                              () =>
                                                  html`<span style="color:var(--color-status-red);text-decoration: underline;"
                                                      >Failed</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.failed,
                                              () =>
                                                  html`<span style="color:var(--color-status-red);text-decoration: underline;"
                                                      >Failed</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.completed,
                                              () =>
                                                  html`<span style="color:var(--color-status-green);text-decoration: underline;"
                                                      >Completed</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.running,
                                              () =>
                                                  html`<span style="color:var(--color-status-blue);text-decoration: underline;"
                                                      >Running</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.exporting,
                                              () =>
                                                  html`<span style="color:var(--color-status-blue);text-decoration: underline;"
                                                      >Exporting</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.starting,
                                              () =>
                                                  html`<span style="color:var(--color-status-blue);text-decoration: underline;"
                                                      >Starting</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.stopped,
                                              () =>
                                                  html`<span style="color:var(--color-gray-4);text-decoration: underline;">Stopped</span>`,
                                          ],
                                          [
                                              RunStatus.stopping,
                                              () =>
                                                  html`<span style="color:var(--color-status-blue);text-decoration: underline;"
                                                      >Stopping</span
                                                  >`,
                                          ],
                                          [
                                              RunStatus.queuing,
                                              () =>
                                                  html`<span style="color:var(--color-gray-4);text-decoration: underline;">Queuing</span>`,
                                          ],
                                          [
                                              RunStatus.waiting,
                                              () => html`<span style="color:teal;text-decoration: underline;">Waiting</span>`,
                                          ],
                                      ],
                                      () => html`${row.status}`
                                  )}</a
                              >`
                            : html``;
                    },
                },
                { name: "menu", align: "center", cellStyle: { width: "20px" }, menu: (row, col) => this.configMenu(row, col), htmlTitle: "More options" },
            ];

            this._userState.selectedIds = undefined; //Cleanup.

            if (isForceRefresh) this._isLoading = true;
            try {
                let result: NetworkResult<ConfigsSummaryViewModel>;
                let chartData;
                if (this._fileterType === "template") {
                    result = await this._configApi.getManyByAgentTemplateAsync(
                        this._userState.selectedSpaceId,
                        this._fileterId,
                        this._pageIndex,
                        this._recordsPerPage,
                        this._sortColumn,
                        this._sortOrder
                    );
                    chartData = await this._configApi.getChartDataAsync(
                        "template",
                        this._userState.selectedSpaceId,
                        this._fileterId,
                        this._pageIndex,
                        this._recordsPerPage,
                        this._sortColumn,
                        this._sortOrder
                    );
                } else if (this._fileterType === "shared-file") {
                    result = await this._configApi.getManyBySharedFileAsync(
                        this._userState.selectedSpaceId,
                        this._fileterId,
                        this._pageIndex,
                        this._recordsPerPage,
                        this._sortColumn,
                        this._sortOrder
                    );
                    chartData = await this._configApi.getChartDataAsync(
                        "shared-file",
                        this._userState.selectedSpaceId,
                        this._fileterId,
                        this._pageIndex,
                        this._recordsPerPage,
                        this._sortColumn,
                        this._sortOrder
                    );
                } else {
                    const label = await this._userState.getSelectedLabel();
                    if (label.isErr) {
                        this._toasterService.showUnexpectedError(label.err.message);
                    } else {
                        result = await this._configApi.getManyByLabelAsync(
                            this._userState.selectedSpaceId,
                            label.value.id,
                            this._pageIndex,
                            this._recordsPerPage,
                            this._sortColumn,
                            this._sortOrder
                        );
                        chartData = await this._configApi.getChartDataAsync(
                            "label",
                            this._userState.selectedSpaceId,
                            label.value.id,
                            this._pageIndex,
                            this._recordsPerPage,
                            this._sortColumn,
                            this._sortOrder
                        );
                    }
                }
                if (chartData.isOk) {
                    this._generalRunsStatus = [
                        chartData.value.runsPassed,
                        chartData.value.runsFailed,
                        chartData.value.runsNotRun,
                        chartData.value.runsProgress,
                    ];
                    this._agentCount = chartData.value.agentsCount;
                    this._last2WeeksData = chartData.value.last2WeeksByDay;
                    this._spaceName = chartData.value.spaceName;
                    this.loadDoughnutChart();
                    this.loadTrendsChart();
                }
                if (result.isOk) {
                    this._data = result.value.configSummaries;
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this._totalRecordCount = result.value.totalRecordCount;
                    this.resetPagination();

                    const isQuickUpdate = result.value.configSummaries.some(
                        (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
                    );
                    this.loadDataTimeout = setTimeout(() => this.loadDataAsync(false), isQuickUpdate ? 2000 : 30000);
                } else {
                    this._toasterService.showUnexpectedError(result.err.message);
                }
            } finally {
                this._isLoading = false;
            }
        }
    }

    private async openEditor(input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number }): Promise<boolean> {
        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        const response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            //url = "https://" + response.value.url;
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        //this.dispatchEvent(new CustomEvent("startloading", { bubbles: true, composed: true, detail: {} }));
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);
        //form.setAttribute("target", "Sequentum Enterprise Editor");

        const params = {
            ...{
                redirectUrl: window.location.href, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
                isProxyRequired: this._authService.orgSettings.isProxyRequired,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }

    private async newSharedFile(input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number }) {
        Router.go(`/edit/shared-file/new`);
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
                this._hasSelectedAgentsWithUnknownValidation =
                    this._dataGrid.selectedRows.filter(
                        (p) =>
                            p.configType !== ConfigType.SharedFile &&
                            (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
                    ).length > 0;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== (this._data?.length ?? 0)) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
            this._hasSelectedAgentsWithUnknownValidation =
                this._dataGrid.selectedRows.filter(
                    (p) =>
                        p.configType !== ConfigType.SharedFile && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
                ).length > 0;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
            this._hasSelectedAgentsWithUnknownValidation =
                this._dataGrid.selectedRows.filter(
                    (p) =>
                        p.configType !== ConfigType.SharedFile && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
                ).length > 0;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadDataAsync();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadDataAsync();
    }

    private resetPagination() {
        //this._pageIndex = 1;
        //this._dataGrid.pageIndex = 1;
        //this._dataPagination.pageIndex = 1;
    }

    private refresh() {
        this.loadDataAsync();
    }

    private openLabelsMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-add-labels",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    /*private openAgentOverridesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({ component: 'se-apply-templates', button: event.currentTarget as HTMLElement, activeProperty: 'active', input: { selectedRows: this._dataGrid.selectedRows, sortOrder: 1 } });
    }*/

    private openAgentTemplatesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-apply-templates",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private openSharedFilesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-assign-shared-files",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
        this._userState.selectedLabelId = event.detail.labelId;
        this._fileterType = this._userState.selectedLabelId > 0 ? "label" : "none";
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        this._fileterType = "template";
        this._fileterId = event.detail.agentTemplateId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/label/-1/template/${event.detail.agentTemplateId}`);
        this.loadDataAsync();
    }
    private filterBySharedFile(event: CustomEvent) {
        event.stopPropagation();
        this._fileterType = "shared-file";
        this._fileterId = event.detail.sharedFileId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/label/-1/shared-file/${event.detail.sharedFileId}`);
        this.loadDataAsync();
    }

    private openMoveMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-move-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private openCopyMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-copy-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private async validateAgents(event: MouseEvent) {
        event.stopPropagation();
        if (!this._userState.isDraftSpace) {
            const agents = this._dataGrid.selectedRows.filter(
                (p) => p.configType !== ConfigType.SharedFile && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
            );
            const count = agents.length;
            if (count > 0) {
                const result = await this._modalService.openConfirmDialogAsync({
                    title: "Validate Agents",
                    body: `Are you sure you want to validate ${count === 1 ? `${agents[0].name}` : `${count} agents`}?`,
                    saveCaption: "Validate",
                });
                if (result.isSave) {
                    const result = await this._configService.api.validateAllAsync(agents.map((p) => p.id as number));
                    if (result.isOk) {
                        for (let i = 0; i < result.value.length; i++) {
                            if (agents.length > i) {
                                agents[i].validationStatus = result.value[i].validationStatus;
                            }
                        }
                        this._dataGrid.requestUpdate();
                    } else if (result.isErr) {
                        this._toasterService.showNetworkError(result.err);
                    }
                }
            }
        }
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        if (this._userState.isDraftSpace) {
            const count = this._dataGrid.selectedRows.length;
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Delete Drafts",
                body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} drafts`}?`,
                saveCaption: "Delete",
            });
            if (result.isSave) {
                const result = await this._draftService.api.deleteAllAsync(this._dataGrid.selectedRows.map((p) => p.id as number));
                if (result.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.loadDataAsync();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        } else {
            const count = this._dataGrid.selectedRows.length;
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Delete Configs",
                body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} configs`}?`,
                saveCaption: "Delete",
            });
            if (result.isSave) {
                const result = await this._configService.api.deleteAllAsync(this._dataGrid.selectedRows.map((p) => p.id as number));
                if (result.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.loadDataAsync();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }
    private onDragOver(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.types.length === 1 && e.dataTransfer.types[0] === "Files") this._dataGrid.showActivated(true);
        else e.dataTransfer.dropEffect = "none";
    }
    private onDragLeave(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this._dataGrid.showActivated(false);
    }
    private async onDragDrop(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this._dataGrid.showActivated(false);
        if (e.dataTransfer.files.length === 0) return;
        if (e.dataTransfer.files.length > 1) {
            this._toasterService.showError(
                "You can only upload one file at a time. Bundle files in a zip file and upload the zip file if you need access to multiple files.",
                "Error uploading files"
            );
            return;
        }
        if (e.dataTransfer.items[0]?.webkitGetAsEntry && e.dataTransfer.items[0].webkitGetAsEntry().isDirectory) {
            this._toasterService.showError(
                "You can only upload one file at a time. Bundle files and directories in a zip file and upload the zip file if you need access to multiple files and directories.",
                "Error uploading directory"
            );
            return;
        }
        const file = e.dataTransfer.files[0];
        let size = Math.round(file.size / 1024);
        if (size < 1) size = 1;
        if (size > 50240) {
            this._toasterService.showError(
                `The file is too big (${(size / 1024).toFixed(2)}MB). Files must have a size less than 50MB.", "Error uploading file`
            );
            return;
        }
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Upload File",
            body: `Are you sure you want to upload the file ${e.dataTransfer.files[0].name} (${size.toFixed(2)}KB).`,
            saveCaption: "Upload",
        });
        if (result.isSave) {
            const uploadResult = await this._fileUploadApi.uploadSharedFileAsync(
                file,
                this._userState.selectedSpaceId,
                this._userState.selectedLabelId,
                function (e) {},
                function (e) {}
            );
            if (uploadResult.isErr) {
                this._toasterService.showNetworkError(uploadResult.err);
            } else {
                this._toasterService.showSuccess(`${file.name} uploaded successfully.`);
                this.refresh();
            }
        }
    }

    private async getAllRunableIdsAsync() {
        const result =
            this._fileterType === "template"
                ? await this._configApi.getRunableIdsByTemplateAsync(this._userState.selectedSpaceId, this._fileterId)
                : await this._configApi.getRunableIdsByLabelAsync(this._userState.selectedSpaceId, this._userState.selectedLabelId);
        if (result.isOk) {
            if (result.value.ids.length > 0) {
                return result.value.ids;
            } else {
                this._toasterService.showError("No selected agents or templates.");
            }
        } else {
            this._toasterService.showNetworkError(result.err);
        }
        return undefined;
    }

    private runMany() {
        this.runManyRows(this._dataGrid.selectedRows);
    }
    private runManyRows(rows: any[]) {
        this._userState.selectedIds = rows.filter((p) => p.configType !== ConfigType.SharedFile && isReadyToRun(p.status)).map((m) => m.id);
        if (this._userState.selectedIds.length > 0) {
            Router.go(
                `/space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}${this._fileterType === "template" ? `/template/${this._fileterId}` : ""}/many-runs`
            );
        } else {
            this._toasterService.showError("No selected agents or templates.");
        }
    }
    private async runAllAsync() {
        if (this._fileterType === "shared-file" || this._userState.selectedSpaceId === 0) return;

        if (this._totalRecordCount > (this._data?.length ?? 0)) {
            this._userState.selectedIds = await this.getAllRunableIdsAsync();
            if (this._userState.selectedIds)
                Router.go(
                    `/space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}${this._fileterType === "template" ? `/template/${this._fileterId}` : ""}/many-runs`
                );
        } else if (this._data) {
            this.runManyRows(this._data);
        }
    }
    private async stopManyAsync() {
        await this.stopManyRowsAsync(this._dataGrid.selectedRows);
    }
    private async stopManyRowsAsync(rows: any[]) {
        const ids = rows
            .filter((p) => p.configType !== ConfigType.SharedFile && isRunningOnServerOrQueingOrWaiting(p.status))
            .map((m) => m.id);
        if (ids.length > 0) {
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Stop All Agents and Templates",
                body: `Are you sure you want to stop ${ids.length} agents and templates?`,
                saveCaption: `Stop ${ids.length} Agents`,
            });
            if (result.isSave) {
                await this._runApi.stopManyConfigsAsync(ids);
                await this.loadDataAsync();
            }
        } else {
            this._toasterService.showError("No selected agents or templates.");
        }
    }
    private async stopAllAsync() {
        if (this._fileterType === "shared-file") return;

        if (this._totalRecordCount > (this._data?.length ?? 0)) {
            const ids = await this.getAllRunableIdsAsync();
            if (ids) {
                const result = await this._modalService.openConfirmDialogAsync({
                    title: "Stop All Agents and Templates",
                    body: `Are you sure you want to stop ${ids.length} agents and templates?`,
                    saveCaption: `Stop ${ids.length} Agents`,
                });
                if (result.isSave) {
                    await this._runApi.stopManyConfigsAsync(ids);
                    await this.loadDataAsync();
                }
            }
        } else if (this._data) {
            await this.stopManyRowsAsync(this._data);
        }
    }

    loadDoughnutChart() {
        const totalRuns = this._generalRunsStatus.reduce((acc, val) => acc + val, 0);
        const passCount = this._generalRunsStatus[0];
        let passPercentage = ((passCount / totalRuns) * 100).toFixed(2);
        if (passPercentage === "NaN") {
            passPercentage = "0";
        }
        const ctx = this._doughnutChartCanvas;
        if (this.doughnutChart) {
            this.doughnutChart.destroy();
        }

        let labels = ["Completed", "Failed", "Not Run", "In Progress"]
        let backgroundColor = [
            "rgba(165, 211, 126, 1)",
            "rgba(255, 113, 96, 1)",
            "rgba(183, 183, 183, 1)",
            "rgba(83, 183, 245, 1)",
        ]
        const allValuesZero = this._generalRunsStatus.every(value => value === 0);

        if (allValuesZero) {
            this._generalRunsStatus = [1];
            labels = [''];
            backgroundColor = ['rgba(183, 183, 183, 1)'];
        }

        this.doughnutChart = new Chart(ctx, {
            type: "doughnut",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "",
                        data: this._generalRunsStatus,
                        backgroundColor: backgroundColor,
                        borderColor: "#ffffff",
                        borderWidth: 2,
                    },
                ],
            },
            options: {
                cutout: "75%",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: !allValuesZero,
                        callbacks: {
                            label: (context) => {
                                const datasetLabel = context.dataset.label;
                                const value = context.parsed;
                                return `${datasetLabel}: ${value}`;
                            },
                        },
                    },
                },
            },
            plugins: [
                {
                    id: "center-text",
                    beforeDraw: (chart) => {
                        const width = chart.width;
                        const height = chart.height;
                        const ctx = chart.ctx;
                        ctx.restore();
                        const dynamicFontSize = height / 7;
                        ctx.font = `${dynamicFontSize}px Rubik, "Open Sans", Arial, Helvetica, sans-serif`;
                        ctx.textBaseline = "middle";
                        let text = !allValuesZero ? `${passPercentage}%` : ""
                        let textX = Math.round((width - ctx.measureText(text).width) / 2)
                        let textY = height / 2 - (dynamicFontSize / 4);
                        ctx.fillText(text, textX, textY);

                        ctx.font = `${dynamicFontSize*0.7}px Rubik, "Open Sans", Arial, Helvetica, sans-serif`;
                        text = !allValuesZero ? `Success` : "No Runs"
                        textX = Math.round((width - ctx.measureText(text).width) / 2)
                        textY = height / 2 + (dynamicFontSize / 1.5)
                        ctx.fillText(text, textX, textY);
                        ctx.save();
                    },
                },
            ],
        });
    }

    loadTrendsChart() {
        const ctx = this._trendsChartCanvas;

        const labels = this._last2WeeksData.map((entry) => {
            const date = new Date(entry.date);
            return `${date.getMonth() + 1}/${date.getDate()}`;
        });

        const failCounts = this._last2WeeksData.map((entry) => entry.failCount);
        const passCounts = this._last2WeeksData.map((entry) => entry.passCount);
        const stackedPassCounts = this._last2WeeksData.map((entry, index) => entry.passCount + failCounts[index]);

        if (this.trendsChart) {
            this.trendsChart.destroy();
        }
        this.trendsChart = new Chart(ctx, {
            type: "line",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Fail Count",
                        data: failCounts,
                        backgroundColor: "rgba(255, 113, 96, 1)",
                        borderColor: "rgba(255, 113, 96, 1)",
                        fill: true,
                        tension: 0.0,
                        pointRadius: 0,
                    },
                    {
                        label: "Success Count",
                        data: stackedPassCounts,
                        backgroundColor: "rgba(165, 211, 126, 1)",
                        borderColor: "rgba(165, 211, 126, 1)",
                        fill: "-1",
                        tension: 0.0,
                        pointRadius: 0,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            callback: function (value, index) {
                                return index % 3 === 0 ? labels[index] : "";
                            },
                            color: "black",
                            padding: 10,
                        },
                        grid: {
                            color: "white",
                            lineWidth: 1,
                            drawOnChartArea: true,
                            z: 1,
                            drawTicks: true,
                            tickLength: 8,
                            tickWidth: 1,
                            tickColor: "black",
                        },
                        border: {
                            color: "black",
                            z: 2,
                        },
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value) {
                                return Number.isInteger(value) ? value : "";
                            },
                            color: "black",
                            padding: 10,
                        },
                        title: {
                            display: false,
                        },
                        grid: {
                            color: "white",
                            display: true,
                            drawTicks: true,
                            tickLength: 8,
                            tickWidth: 1,
                            tickColor: "black",
                        },
                        border: {
                            color: "black",
                            z: 2,
                        },
                        suggestedMax: Math.max(...stackedPassCounts) + 1,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        mode: "index",
                        intersect: false,
                        callbacks: {
                            label: function (tooltipItem) {
                                if (tooltipItem.datasetIndex === 0) {
                                    return `Fail Count: ${failCounts[tooltipItem.dataIndex]}`;
                                } else {
                                    return `Success Count: ${passCounts[tooltipItem.dataIndex]}`;
                                }
                            },
                        },
                    },
                },
            },
        });
    }

    render() {
        const showStopAll =
            !this._hasSelectedRows &&
            this._fileterType !== "shared-file" &&
            !this._userState.isDraftSpace &&
            this._data &&
            this._data.some((m) => isRunningOnServerOrQueingOrWaiting((m as ConfigSummaryViewModel).status)); //It look strange to show stop all if not at least one agent is running in the current pagination page (although it's correct to show the button since there could be agents running on a different page)
        const showStopMany =
            this._hasSelectedRows &&
            !this._userState.isDraftSpace &&
            this._dataGrid.selectedRows.some((m) => isRunningOnServerOrQueingOrWaiting(m.status));
        const showRunMany =
            this._hasSelectedRows &&
            this._dataGrid.selectedRows.some((m) => m.configType !== ConfigType.SharedFile && isReadyToRun(m.status));
        const showRunAll =
            !this._hasSelectedRows &&
            this._userState.selectedSpaceId > 0 &&
            this._data &&
            this._data.some(
                (m) =>
                    (m as ConfigSummaryViewModel).configType !== ConfigType.SharedFile && isReadyToRun((m as ConfigSummaryViewModel).status)
            ); //It look strange to show run all if not at least one agent can be run in the current pagination page (although it's correct to show the button since there could be agents on a different page that could be run)
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                ${!this._userState.isDraftSpace ? html`
                <div class="charts">
                    <div class="scroll-container" style="flex: 1;">
                        <div style="font-size: 1.5em;" class="h3">${this._spaceName} Summary</div>
                        <div class="left-chart" style="justify-content: space-around;">
                            <div class="count" style="text-align: center;flex: 1;">
                                <span style="font-size: 4rem;">${this._agentCount}</span>
                                <br />
                                <span style="color: #999999;font-weight: 900;">Agent Count</span>
                            </div>
                            <div style="min-height: 110px;height: calc(20vh - 50px);flex: 1;">
                                <canvas id="doughnutChartCanvas"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="scroll-container" style="flex: 1;">
                        <div style="font-size: 1.5em;" class="h3">Run History</div>
                        <div class="right-chart" style="width: 100%;min-height: 120px;height: calc(20vh - 40px);">
                            <canvas style="" id="trendsChartCanvas"></canvas>
                        </div>
                    </div>
                </div>` : html``}

                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${!this._data || this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                                  ${!this._userState.isDraftSpace
                                      ? html`<se-secondary-button
                                                @mousedown=${(event) => this.openLabelsMenu(event)}
                                                ${htmlTitle("Apply labels")}
                                                .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                icon="far fa-tag"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openAgentTemplatesMenu(event)}
                                                ${htmlTitle("Apply templates")}
                                                .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                icon="far fa-cubes"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openSharedFilesMenu(event)}
                                                ${htmlTitle("Assign shared files")}
                                                .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                icon="far fa-file"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openMoveMenu(event)}
                                                ${htmlTitle("Move to...")}
                                                .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                icon="far fa-truck"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openCopyMenu(event)}
                                                ${htmlTitle("Copy to...")}
                                                .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                icon="far fa-copy"
                                            ></se-secondary-button>
                                            ${this._hasSelectedAgentsWithUnknownValidation
                                                ? html`<se-secondary-button
                                                      @mousedown=${(event) => this.validateAgents(event)}
                                                      ${htmlTitle("Validate selected agents.")}
                                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                      icon="far fa-clipboard-check"
                                                  ></se-secondary-button>`
                                                : html``}
                                            ${showRunMany
                                                ? html` <se-secondary-button
                                                      @mousedown=${() => this.runMany()}
                                                      ${htmlTitle("Run all selected agents and templates")}
                                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                      icon="far fa-running"
                                                  ></se-secondary-button>`
                                                : html``}
                                            ${showStopMany
                                                ? html` <se-secondary-button
                                                      @mousedown=${() => this.stopManyAsync()}
                                                      ${htmlTitle("Stop all selected running agents and templates")}
                                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                                      icon="far fa-circle-stop"
                                                  ></se-secondary-button>`
                                                : html``} `
                                      : html``}
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                                  ${showRunAll
                                      ? html` <se-secondary-button
                                            @click=${this.runAllAsync}
                                            ${htmlTitle("Run all listed agents and templates")}
                                            .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                            icon="far fa-person-running-fast"
                                        ></se-secondary-button>`
                                      : html``}
                                  ${showStopAll
                                      ? html` <se-secondary-button
                                            @click=${this.stopAllAsync}
                                            ${htmlTitle("Stop all running agents and templates")}
                                            .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                            icon="far fa-circle-stop"
                                        ></se-secondary-button>`
                                      : html``}
                              `}
                    </div>
                    <div style="display:flex; gap:10px">
                        <se-space-search-editor></se-space-search-editor>
                        <se-dropdown-button
                            id="newAgentButton"
                            text="New Agent"
                            .menu=${this.newMenu()}
                            .action="${() => this.openEditor({ configType: ConfigType.Agent, spaceId: this._userState.selectedSpaceId })}"
                            action-text="Opening..."
                            action-delay="500"
                            min-sizing="growth"
                        ></se-dropdown-button>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    .defaultSorted=${this._sortColumn}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    @filterbylabel=${this.filterByLabel}
                    @filterByAgentTemplate=${this.filterByAgentTemplate}
                    @filterBySharedFile=${this.filterBySharedFile}
                    @dragover=${this.onDragOver}
                    @dragleave=${this.onDragLeave}
                    @drop=${this.onDragDrop}
                    placeholder="No data available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                <div style="overflow:hidden;display:flex;justify-content: center;">
                    <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .charts {
            display: flex;
            gap: 20px;
            height: 20vh;
            min-height: 160px;
        }
        .left-chart {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .right-chart {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .scroll-container {
            min-height: 0;
            overflow: hidden;
            padding: 10px 10px 20px 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            margin-bottom: 5px;
        }
    `;
}
