import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import "element-internals-polyfill";
import { BaseEditor } from "./base-editor";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-number-editor")
export class NumberEditorElement extends LitElement implements BaseEditor {
    @state() private _value?: number;
    get value() {
        return this._value;
    }
    set value(newValue: number) {
        if (this.emptyalue === undefined || this.emptyalue === null)
            this._value = newValue === undefined || newValue === null ? undefined : newValue;
        else this._value = newValue === this.emptyalue ? undefined : newValue;
    }

    get liveValue() {
        return isNaN(this._inputElement?.valueAsNumber) ? this.emptyalue : this._inputElement?.valueAsNumber;
    }

    @property() label?: string;
    @property() labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    @property({ type: Number }) step?: number;
    @property() width = "70px";
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() min?: string;
    @property() max?: string;
    @property() placeholder = "";
    @property({ attribute: "input-width" }) inputWidth?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property() type: "number" | "range" = "number";
    @property() gap;
    @property({ attribute: "editor-size" }) editorSize: "small" | "nomal";
    @property({ attribute: "empty-value", type: Number }) emptyalue = undefined;
    @property({ type: Boolean, attribute: "use-auto-complete" }) useAutoComplete = false;

    @query("#input") private _inputElement: HTMLInputElement;

    updateValue(event) {
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    disable(isDisable = true) {
        this.disabled = isDisable;
    }

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const flexDirection =
            (this.labelPosition === "left" && "row-reverse") ||
            (this.labelPosition === "top" && "column-reverse") ||
            (this.labelPosition === "right" && "column") ||
            (this.labelPosition === "bottom" && "row");
        const align = this.labelPosition === "left" || this.labelPosition === "right" ? "center" : "start";
        const style = {
            display: "flex",
            flexDirection: flexDirection,
            alignItems: align,
            gap: this.gap !== undefined ? this.gap : this.labelPosition === "left" || this.labelPosition === "right" ? "5px" : "2px",
        };
        const font = { font: this.editorSize === "small" ? "var(--font-input-small)" : "var(--font-input)" };
        return html`
            <div style="${styleMap({ ...style, ...font })}">
                <input
                    style="${styleMap({ ...font, width: this.inputWidth, boxSizing: "border-box" })}"
                    id="input"
                    name="${this.name}"
                    type="${this.type}"
                    .valueAsNumber="${live(this.value)}"
                    @input="${this.updateValue}"
                    min="${ifDefined(this.min)}"
                    max="${ifDefined(this.max)}"
                    maxlength="${ifDefined(this.maxlength)}"
                    minlength="${ifDefined(this.minlength)}"
                    step="${ifDefined(this.step)}"
                    placeholder="${ifDefined(this.placeholder)}"
                    ?required="${this.required}"
                    ?disabled="${this.disabled}"
                    ?readonly=${this.readonly}
                    autocomplete=${this.useAutoComplete ? "on" : "off"}
                />
                <label for="input">${this.label}</label>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
        }
        :host([hidden]) {
            display: none;
        }
        input:disabled + label {
            color: gray;
        }
        input:read-only {
            color: gray;
        }
        input.invalid {
            outline: 2px solid pink;
        }
    `;
}
